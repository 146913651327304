import React, { useState, useEffect } from "react";
import style from "./profile.module.css";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, Dialog, DialogType, TextField, DialogFooter, DefaultButton, Separator, Stack } from "office-ui-fabric-react";
import { useParams } from "react-router-dom";

export const ClientProfileView = () => {
  const [hideDialog, setHideDialog] = useState(true);
  const [profile, setProfile] = useState({ firstName: "", lastName: "", jobTitle: "", businessPartnerNumber: "", streetAddress: "", locale: "", city: "", type: "", phoneNumber: "", companyName: "", paymentTerms: "" });
  const [payload, setPayload] = useState({});
  const [deactivateName, setDeactivateName ] = useState();
  const [isDeactivateDialogClosed, setIsDeactivateDialogClosed] = useState(true);

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const { clientId } = useParams();

  useEffect(() => {
    request.get(`clients/${clientId}`).then((response) => {
      if (response.data.success === true && response.data.message === "CLIENT FOUND.") {
        setProfile(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    }).catch(error => {
      console.warn(error);
      alert("Something went wrong");

    });
    request.get(`users/${clientId}`).then((response) => {
      if (response.data.success === true && response.data.message === "CLIENT FOUND.") {
        setProfile(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    }).catch(error => {
      console.warn(error);
      alert("Something went wrong");

    });
  }, [clientId]);

  const editAccount = () => {
    request.put("user/profile", payload).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("done")
      } else {
        alert("Something went wrong");
      }
    })
  }
  const handleChangeDeactivateName = (e: any) => {
    setDeactivateName(e.target.name)
  }

  const deactivateAccount = () => {
    request.put(`admin/users/${clientId}`, {'status': 'INACTIVE'}).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Client Deactivated");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    })
  }

  const toggleDialog = () => {
    setHideDialog(!hideDialog);
  }

  const toggleDeactivateDialogClosed = () => {
    setIsDeactivateDialogClosed(!isDeactivateDialogClosed);
  }

  const { firstName, lastName, jobTitle, businessPartnerNumber, streetAddress, locale, city, type, phoneNumber, companyName, paymentTerms} = profile;
  return (
    <div className={style.card}>
      <header className={style.header}>
        <div className={style.details}>
          {type === "organisation" ?
            <h2 className={style.name}>{companyName}</h2> :
            <h2 className={style.name}>{firstName} {lastName}</h2>
          }
          <p className={style.value}>{jobTitle}</p>
          <address className={style.address}>
            {streetAddress}<br />
            {locale}, {city}<br />
          </address>
          <div className={style.settings}>
            <div className={style.setting}>
              <div>
                {type === "organisation" ? <p className={style.value}>Business Partner Number: {businessPartnerNumber}</p> : null}
                <p className={style.value}>Phone Number: {phoneNumber}</p>
                <p className={style.value}>{type === "organisation" ? "Organisational Account" : "Personal Account"}</p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Stack horizontal tokens={{ childrenGap: 15 }} style={{ marginTop: 20 }} >
        <PrimaryButton text="Edit Details" onClick={toggleDialog} />
        <DefaultButton text="Deactivate" onClick={toggleDeactivateDialogClosed} />
      </Stack>
      <Dialog hidden={hideDialog} isDarkOverlay={true} minWidth={500} onDismiss={toggleDialog} dialogContentProps={{ type: DialogType.normal, title: 'Edit Account Details' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <TextField label="Phone Number" name="phoneNumber" defaultValue={phoneNumber} onChange={handleChange} />
        <Separator>Billing Address</Separator>
        <TextField label="Street Address" defaultValue={streetAddress} onChange={handleChange} />
        <TextField label="Locale" name="locale" defaultValue={locale} onChange={handleChange} />
        <TextField label="City" name="city" defaultValue={city} onChange={handleChange} />
        {type === "organisation" ? <TextField label="Payment Terms" name="paymentTerms" defaultValue={paymentTerms} onChange={handleChange} multiline autoAdjustHeight/> : null}
        <DialogFooter>
          <PrimaryButton text="Save Changes" onClick={editAccount} />
          <DefaultButton text="Cancel" onClick={toggleDialog} />
        </DialogFooter>
      </Dialog>
      <Dialog hidden={isDeactivateDialogClosed} onDismiss={toggleDeactivateDialogClosed} dialogContentProps={{ type: DialogType.normal, title: 'Set client as inactive', subText: `You are about to remove ${profile.firstName} as a client. Please type ('${profile.firstName}') to confirm that is what you want to do`}} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 }},}}>
        <TextField label="Client Name"  onChange={handleChangeDeactivateName} />
        <DialogFooter>
          <PrimaryButton onClick={deactivateAccount} text="Deactivate Client" disabled={deactivateName !== profile.firstName} />
          <DefaultButton onClick={toggleDeactivateDialogClosed} text="Cancel" />
        </DialogFooter>
    </Dialog>
    </div>
  )
}


