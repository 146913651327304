import React, { useState, useEffect } from "react"
import style from "./confirm-trip.module.css";
import { Map, SectionHeading, LocationItem } from "../../components";
import { useParams, useHistory } from "react-router-dom";
import { request } from "../../helpers/request-helper";
import { PrimaryButton } from "office-ui-fabric-react";
import * as moment from 'moment';

export const DriverConfirmTripView = () => {
  const [trip, setTrip] = useState();
  let { tripId } = useParams();
  let history = useHistory();

  useEffect(() => {
    request.get(`driver/trips/${tripId}`).then((response) => {
      if (response.data.success === true && response.data.message === "TRIP FOUND.") {
        setTrip(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    });

  }, [tripId]);

  const acceptTrip = () => {
    request.post(`driver/trips/${tripId}/status`, { status : "ACCEPTED" }).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("You have succesfully accepted this trip");
        history.push(`trips/${tripId}`);
      } else {
        console.error("Something went wrong");
      }
    });
  }

  if (!trip) {
    return <p>Loading</p>
  } else {
    const { cost, currency, deliveryDate, dropoffLocation, load, name, pickupLocation, tonnage, vehicleType } = trip;

    const date = moment.utc(deliveryDate).format("D MMMM YYYY");

    return (
      <div className={style.wrapper}>
        <SectionHeading title="Shipment Details" style={{ textAlign: "center" }} />
        <div className={style.grid}>
            <p className={style.title}>Delivery of {name}</p>
                <p>{load.description}</p>
                {load.image ? <img style={{ width: "80%" }} src={load.image} alt="Load Image" /> : null}
                <p className={style.date}>Vehicle Type: {vehicleType.name}</p>
            <p className={style.date}>Scheduled Shipment Date: {date}</p>
            <p className={style.date}>Shipment Weight: {load.tonnage} tonnes</p>
            <div className={style.map}>
              <Map lat={dropoffLocation.geoLocation.coordinates[0]} lng={dropoffLocation.geoLocation.coordinates[1]} />
            </div>
            <p className={style.description}>{load.description}</p>
            <p className={style.meta}>Pickup Point</p>
            <LocationItem {...pickupLocation} />
            <p className={style.meta}>Delivery Point</p>
            <LocationItem {...dropoffLocation} />
              <p className={style.cost}>Total Cost: {currency}{cost}</p>
          </div>
          <div className={style.buttons}>
            <PrimaryButton text="Accept This Trip" onClick={acceptTrip}/>
          </div>
      </div>
    )
  }
}