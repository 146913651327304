import React, { CSSProperties } from "react";
import styles from "./section-heading.module.css";


interface ISectionHeading {
  title: string;
  style?: CSSProperties;
}

export const SectionHeading = (props: ISectionHeading) => {
  const {title, style} = props;
  return (
  <h3 style={style} className={styles.heading}>{title}</h3>
  )
}