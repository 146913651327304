import React, { useState, useEffect } from "react";
import { SectionHeading } from "../../components";
import style from "./admins.module.css";
import { PrimaryButton, IColumn, ShimmeredDetailsList, SelectionMode, Dialog, DialogType, TextField, DialogFooter, DefaultButton } from "office-ui-fabric-react";
import { request } from "../../helpers/request-helper";

export const AdminsView = () => {

  const init: any[] = [];
  const [admins, setAdmins] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteDialogClosed, setIsDeleteDialogClosed] = useState(true);
  const [isDialogClosed, setIsDialogClosed] = useState(true);
  const [adminToBeDeleted, setAdminToBeDeleted] = useState({id:'',  name: '', email:''});
  const [deleteEmail, setDeleteEmail ] = useState();
  const [payload, setPayload] = useState({type : "admin", password : "movingStuff"});
  
  const columns: IColumn[] = [{
    key: 'nameColumn',
    name: 'User Name',
    fieldName: 'name',
    minWidth: 100,
  }, {
    key: 'emailColumn',
    name: 'Email',
    fieldName: 'email',
    minWidth: 200,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 200,
  },
  {
    key: 'typeColumn',
    name: 'User Type',
    fieldName: 'type',
    minWidth:100
  }, 
  ]


  useEffect(() => {
      request.get("/admin/users").then((response) => {
          if (response.data.success === true && response.data.message === "ALL USERS FOUND.") {
              setAdmins(response.data.data.users.filter((user: any) => {return user.type === "admin"}));
              setIsLoading(false)
          } else {
              console.error("Something went wrong");
          }
      });
  }, []);

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const handleChangeDeleteEmail = (e: any) => {
    setDeleteEmail(e.target.value);
  }

  const addAdmin = () => {
    request.post("admin/admins", payload).then((response) => {
      if(response.data.success === true){
        alert("An email has been sent to the new administrator");
      } else if (response.data.success === false && response.data.message === "INVALID TOKEN."){
        alert("Your account is not authorised to add admins");
      } else {
        alert("Something went wrong");
      }
    }).catch(() => {
      alert("Something went wrong");
    });
    toggleDialogClosed();
  }

  const deleteAdmin = () => {
    request.delete(`admin/users/${adminToBeDeleted.id}`).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Admin Deleted");
        toggleDeleteDialogClosed();
        window.location.reload();
      } else {
        alert("Something went wrong");
        toggleDeleteDialogClosed();
      }
    })
  }

  const toggleDialogClosed = () => {
    setIsDialogClosed(!isDialogClosed);
  }

  const toggleDeleteDialogClosed = () => {
    setIsDeleteDialogClosed(!isDeleteDialogClosed);
  }

  const _onItemInvoked = (item: any): void => {
    setAdminToBeDeleted(item)
    toggleDeleteDialogClosed();
  }

return (
  <div>
    <div>
      <SectionHeading title="Platfrom Access" />
      <p className="section-description">Platform Administrators</p>
      <div className={style.controls}>
        <PrimaryButton text="Add an Admin" onClick={toggleDialogClosed}/> 
      </div>
    </div>

    <div className={style.cards}>
    {admins ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={admins} enableShimmer={isLoading} columns={columns} onItemInvoked={_onItemInvoked} /> : null}
    </div>

    <Dialog hidden={isDialogClosed} onDismiss={toggleDialogClosed} dialogContentProps={{ type: DialogType.normal, title: 'Add an Administrator', subText: 'You are about to add another administrator to the platform. They will be able to access this dashboard and access its features.'}} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 }},}}>
        <TextField label="First Name" name="firstName" onChange={handleChange} />
        <TextField label="Last Name" name="lastName" onChange={handleChange} />
        <TextField label="Administrator Email" type="email" name="email" onChange={handleChange} />
        <DialogFooter>
          <PrimaryButton onClick={addAdmin} text="Add Administrator" />
          <DefaultButton onClick={toggleDialogClosed} text="Cancel" />
        </DialogFooter>
    </Dialog>
    <Dialog hidden={isDeleteDialogClosed} onDismiss={toggleDeleteDialogClosed} dialogContentProps={{ type: DialogType.normal, title: 'Remove an Administrator', subText: `You are about to remove ${adminToBeDeleted.name} as an Administrastor. Please type their email address ('${adminToBeDeleted.email}') to confirm that is what you want to do`}} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 }},}}>
        <TextField label="Administrator Email" type="email"  onChange={handleChangeDeleteEmail} />
        <DialogFooter>
          <PrimaryButton onClick={deleteAdmin} text="Delete Administrator" disabled={deleteEmail !== adminToBeDeleted.email} />
          <DefaultButton onClick={toggleDeleteDialogClosed} text="Cancel" />
        </DialogFooter>
    </Dialog>
  </div>
)
}