import React, { useState } from "react";
import style from "./pages.module.css"
import { AccountsHeader } from "../../components"
import { NavLink, Redirect, useHistory, useParams } from "react-router-dom";
import { authRequest } from "../../helpers/request-helper";
import { TextField, PrimaryButton, DefaultButton, IStackTokens, Stack } from "office-ui-fabric-react";
import { LoginContext } from "../../App";

export const LoginPage = () => {
    const [payload, setPayload] = useState({ email: "", password: "" });
    let history = useHistory();
    let { invalidToken }  = useParams();

    const handleChange = (e: any) => {
        setPayload({ ...payload, [e.target.name]: e.target.value })
    }

    const login = () => {
        authRequest.post("login", payload).then((response) => {
            if (response.data.success === true && response.data.message === "LOGIN SUCCESSFUL.") {
                const _token = response.data.data.token
                localStorage.setItem("token", _token);
                window.location.reload();
            } else if (response.data.success === false && response.data.message === "INVALID CREDENTIALS.") {
                alert("Signing in failed, your details are not quite right");
            } else if (response.data.message === "USER NOT FOUND.") {
                alert("No account exists with those details, signup instead");
            } else if (response.data.message === "PENDING VERIFICATION.") {
                // eslint-disable-next-line no-restricted-globals
                let redirect: boolean = confirm("Your account is not yet verified, click OK to resend verification email");
                debugger;
                if(redirect){
                    history.push('/resend-verification');
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            if (err.message === "Network Error") {
                alert("You do not seem to be connected to the Internet");
            } else if (err === 404) {
                alert("No account exists with those details, signup instead");
                console.log(err);
            } else {
                alert("Could not connect to the server");
            }
        });
    }
    const stackTokens: IStackTokens = {
        childrenGap: 10,
        padding: "10px 0"
      };
    return (
        <LoginContext.Consumer>
            {([token, setToken]) => {
                if (token && !invalidToken) {
                    return <Redirect to="/" />
                } else
                    return (
                        <div className={style.wrapper}>
                            <AccountsHeader />
                            <form className={style.card}>
                                <h2 className={style.heading}>Login to your Account</h2>
                                <Stack tokens={stackTokens}>
                                <TextField label="Email Address" placeholder="Email Address" name="email" type="email" onChange={handleChange} />
                                <TextField label="Password" placeholder="Password" type="password" name="password" onChange={handleChange} />
                                <div className={style.buttons}>
                                    <PrimaryButton text="Login" onClick={login} /><DefaultButton text="Cancel" type="reset" />
                                </div>
                                </Stack>
                                <p className={style.instruction}>Dont have an account? <NavLink to="/signup">Sign up here</NavLink> or <NavLink to="/forgot-password">change your password</NavLink></p>
                            </form>
                        </div>
                    )
            }}
        </LoginContext.Consumer>
    )
}
