import React, { useState, useEffect } from "react";
import { Stack, IStackStyles, Text, PrimaryButton } from "office-ui-fabric-react";
import { request } from "../../helpers/request-helper";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { topStackTokens, stackTokens, buttonStackTokens } from "../../util/stackTokens";

const statusText: any = {
    ACCEPTED: "Your shipment has been assigned a driver and will be intransit soon.",
    PENDING: "Your shipment will be assigned to a driver soon.",
    DISPATCHED: "Your shipment has been dispatched.",
    CANCELLED: "This shipmenet has been cancelled",
    DELIVERED: "Your shipment was delivered"
}

export const TripView = () => {
    const [trip, setTrip] = useState();
    let { tripId } = useParams();
    let history = useHistory();

    const stackStyles: IStackStyles = {
        root: {
            maxWidth: 1200,
            display: "flex"
        }
    };

    useEffect(() => {
        request.get(`user/trips/${tripId}`).then((response) => {
            if (response.data.success === true && response.data.message === "USER TRIP FOUND.") {
                setTrip(response.data.data);
            } else {
                console.error("Something went wrong");
            }
        });

    }, [tripId]);

    const cancelTrip = () => {
        request.put(`user/trips/${tripId}`, {
            status: "CANCELLED",
        }).then((response) => {
            if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
                alert("Trip Cancelled")
                history.push(`/trips`);
            } else {
                console.error("Something went wrong");
            }
        }).catch(error => {
            console.error(error);
            alert("Something went wrong. Please check your internet connection");
        });
    }

    const acknowledgeDelivery = () => {
        request.post(`driver/trips/${tripId}`, { "status": "DELIVERED" }).then((response) => {
            if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
                alert("Goods marked as delivered. Thank you for using Intransit.");
                window.location.reload();
            } else {
                alert("Something went wrong");
            }
        });
    }



    if (!trip) {
        return <p>Loading</p>
    } else {
        const { status, load, deliveryDate, dropoffLocation, driver, name, pickupLocation, cost, currency, vehicleType } = trip;
        const p = pickupLocation;
        const d = dropoffLocation;
        const date = moment.utc(deliveryDate).format("D MMMM YYYY");

        return (
            <Stack horizontalAlign="center">
                <Stack styles={stackStyles} tokens={stackTokens}>
                    <Stack tokens={topStackTokens}>
                        <Text variant="xxLarge">Your Shipment: {name} </Text>
                        {load.image ? <img style={{ width: "80%" }} src={load.image} alt="Load" /> : null}
                        <Text variant="mediumPlus">{load.description} ({load.tonnage} {load.tonnage === 1? "tonne" : "tonnes" })</Text>
                        <Text variant="mediumPlus">Status: {statusText[status]} {(status === "CANCELLED" || status === "DELIVERED" || status === "DELAYED")? null : `Expected Delivery date is ${date}`  }</Text>
                        {status === "PENDING" || status === "DISPATCHED" ?
                            <Stack horizontal tokens={buttonStackTokens}>
                                <PrimaryButton text={status === "PENDING" ? "Cancel Shipment" : "Acknowledge Delivery"} onClick={status === "PENDING" ? cancelTrip : acknowledgeDelivery }/>
                            </Stack> : null
                        }
                    </Stack>
                    {status === "ACCEPTED" || status === "DELIVERED" ?
                        <div>
                            <Text variant="mediumPlus" block>Your Driver: Assigned to {driver.name} (Driving a {vehicleType.name})</Text>
                        </div> :
                        null
                    }
                    <div>
                        <Text variant="mediumPlus" block>Load Collection point: {p.name} ({p.streetAddress}, {p.locale}, {p.city})</Text>
                        <Text variant="mediumPlus" block>Load Dropoff point: {d.name} ({d.streetAddress}, {d.locale}, {d.city})</Text>
                    </div>
                    <div>
                        <Text variant="medium" block>Your Trip Cost: {currency} {cost}</Text>
                    </div>
                </Stack>
            </Stack>
        )
    }
}