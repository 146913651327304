import React, { useState, useEffect } from "react";
import style from "./profile.module.css";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, Dialog, DialogType, TextField, DialogFooter, DefaultButton, Toggle, Stack } from "office-ui-fabric-react";
import { useParams } from "react-router-dom";


export const DriverProfileView = () => {
  const [hideDialog, setHideDialog] = useState(true);
  const [profile, setProfile] = useState({firstName:"",lastName:"",nationalId:"",phoneNumber:"",whatsappNumber:"", licenseNumber:""});
  const [payload, setPayload] = useState({});
  let { driverId } = useParams();
  const [deactivateName, setDeactivateName ] = useState();
  const [isDeactivateDialogClosed, setIsDeactivateDialogClosed] = useState(true);

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    request.get(`admin/drivers/${driverId}`).then((response) => {
       if (response.data.success === true && response.data.message === "DRIVER FOUND.") {
         setProfile(response.data.data);
       } else {
         console.error("Something went wrong");
       }
     });
  }, [driverId]);

  const editAccount = () => {
    request.put(`admin/drivers/${driverId}`, payload).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("done");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    })
  }

  const handleChangeDeactivateName = (e: any) => {
    setDeactivateName(e.target.name)
  }

  const deactivateAccount = () => {
    request.put(`admin/users/${driverId}`, {'status': 'INACTIVE'}).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Driver Deactivated");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    })
  }

  const toggleDialog = () => {
    setHideDialog(!hideDialog);
  }

  const toggleDeactivateDialogClosed = () => {
    setIsDeactivateDialogClosed(!isDeactivateDialogClosed);
  }

  const { firstName,lastName,nationalId, phoneNumber, whatsappNumber, licenseNumber} = profile;
  return (
    <div className={style.card}>
      <header className={style.header}>
              <div className={style.details}>

                      <h2 className={style.name}>{firstName} {lastName}</h2> 
          <div className={style.settings}>
            <div className={style.setting}>
              <div>
                <p className={style.value}>National ID Number: {nationalId}</p>
                <p className={style.value}>License Number: {licenseNumber}</p>
                <p className={style.value}>Phone Number: {phoneNumber}</p>
                <p className={style.value}>WhatsApp Number: {whatsappNumber}</p>
              </div>
            </div>
          </div>

        </div>
      </header>          
      <Stack horizontal tokens={{childrenGap: 20}} style={{ marginTop: 20 }} >
            <PrimaryButton text="Edit Details" onClick={toggleDialog} />
            <DefaultButton text="Deactivate" onClick={toggleDeactivateDialogClosed} />
      </Stack>
      <Dialog hidden={hideDialog} isDarkOverlay={true} minWidth={500} onDismiss={toggleDialog} dialogContentProps={{ type: DialogType.normal, title: 'Edit Account Details' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <TextField label="First Name" name="firstName" defaultValue={firstName} onChange={handleChange} />
        <TextField label="Last Name" name="lastName" defaultValue={lastName} onChange={handleChange} />
           <div style={{ margin: "20px 0" }}>
              <Toggle label="Account Status" defaultChecked onText="Verified" offText="Not Verified" />
           </div>
        <TextField label="Phone Number" name="phoneNumber" defaultValue={phoneNumber} onChange={handleChange} />
        <TextField label="WhatsApp Number" name="whatsappNumber" defaultValue={whatsappNumber} onChange={handleChange}/>
        <TextField label="National ID Number" name="nationalId" defaultValue={nationalId} onChange={handleChange} />
        <TextField label="License Number" name="licenseNumber" defaultValue={licenseNumber} onChange={handleChange}/>
        <DialogFooter>
          <PrimaryButton text="Save Changes" onClick={editAccount} />
          <DefaultButton text="Cancel" onClick={toggleDialog} />
        </DialogFooter>
      </Dialog>
      <Dialog hidden={isDeactivateDialogClosed} onDismiss={toggleDeactivateDialogClosed} dialogContentProps={{ type: DialogType.normal, title: 'Set driver as inactive', subText: `You are about to remove ${profile.firstName} as a driver. Please type ('${profile.firstName}') to confirm that is what you want to do`}} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 }},}}>
        <TextField label="Driver Name"  onChange={handleChangeDeactivateName} />
        <DialogFooter>
          <PrimaryButton onClick={deactivateAccount} text="Deactivate Driver" disabled={deactivateName !== profile.firstName} />
          <DefaultButton onClick={toggleDeactivateDialogClosed} text="Cancel" />
        </DialogFooter>
    </Dialog>
    </div>
  )
}


