import React, { useState, useEffect } from "react";
import { request } from "../../helpers/request-helper";
import { useHistory } from "react-router-dom";
import { IColumn, ShimmeredDetailsList, SelectionMode, Stack, Text } from "office-ui-fabric-react";
import moment from "moment";
import { LoginContext } from "../../App";
import { IToken } from "../../interfaces/Token";
import { stackTokens, topStackTokens } from "../../util/stackTokens";

export const DriverHomeView = () => {
  let init: any[] = []
  const [trips, setTrips] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  const tripColumns: IColumn[] = [{
    key: 'deliveryDate',
    name: 'Shipping Date',
    fieldName: 'deliveryDate',
    minWidth: 50,
    maxWidth: 140,
    onRender: (item: any) => {
      const date = moment.utc(item.deliveryDate).format("D MMMM YYYY");
      return date;
    }
  }, {
    key: 'nameColumn',
    name: 'Delivery Name',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 80,
  }, {
    key: 'costColumn',
    name: 'Cost',
    fieldName: 'cost',
    minWidth: 80,
  }];

  const _onItemInvoked = (item: any): void => {
    history.push(`/driver/confirm-trip/${item.id}`)
  }

  useEffect(() => {
    request.get("/driver/trips/available").then((response) => {
      if (response.data.success === true && response.data.message === "AVAILABLE TRIPS FOUND.") {
        const _trips = response.data.data.trips;

        setTrips(_trips.filter((trip :any) => { return Boolean(trip.status === "PENDING") }));
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <LoginContext.Consumer>
      {([token, setToken]) => {
        const _token = token as IToken;
        return (
          <Stack tokens={stackTokens}>
            <Stack tokens={topStackTokens}>
              <Text variant="xxLarge">Good Day {_token.user.name}</Text>
              <Text variant="mediumPlus" block>{trips.length > 0 ? `Here is a list of available trips for you` : 'There are currently no available trips for you'}</Text>

              <div>
                {trips.length > 0 || isLoading ?
                  <ShimmeredDetailsList selectionMode={SelectionMode.none} items={trips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onItemInvoked} /> : null
                }
              </div>
            </Stack>
          </Stack>
        )
      }}
      </LoginContext.Consumer>
  )
}
