import React, { FormEvent } from "react";
import style from "./payment-prompt.module.css"
import { PrimaryButton, DefaultButton, Dialog, DialogType, TextField, DialogFooter, IDropdownOption, Dropdown } from "office-ui-fabric-react";
import { useParams, useHistory } from "react-router-dom";
import { request } from "../../helpers/request-helper";

const paymentOptions : IDropdownOption[] = [
  {key: 'ecocash', text: 'Ecocash'}, 
  {key: 'telecash', text: 'Telecash'}
]
export const PromptPaymentView = () => {
  let { tripId } = useParams();
  let history = useHistory();
  const [hideDialog, setHideDialog] = React.useState(true);
  const [payload, setPayload] = React.useState({ paymentMethod: 'ecocash'});
  const widgetRef = React.useRef();
  const [docUrl, setDocUrl] = React.useState("");

  const confirmTrip = () => {
    request.put(`user/trips/${tripId}`, {
      status: "PENDING",
    }).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        history.push(`/trips/${tripId}`);
      } else {
        console.error("Something went wrong");
      }
    });
  }

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  } 
  const handleDocumentUpload = (documentLink: string) => {
    setDocUrl(documentLink);
    console.log("Document Uploaded");
  }

  const handlePayment = () => {
    request.post(`user/trips/${tripId}/pay`, payload).then((response) => {
      if (response.data.success === true && response.data.message === "PAYMENT INITIALIZED.") {
        alert(response.data.data.instructions);
        history.push(`/trips/${tripId}`);
      } else if (response.data.success === true && response.data.message === "TRIP ALREADY PAID.") {
        alert("This trip has already been paid. You will be redirected to the trip page")
        history.push(`/trips/${tripId}`);
      } else if (response.data.success === false && response.data.message === "TRIP NOT FOUND.") {
        alert("The trip you are trying to pay for no longer exists");
        history.push(`/trips`);
      } else if (response.data.success === false && response.data.message === "PAYMENT NOT INITIALIZED.") {
        alert(`Something went wrong... payment process failed to start:${response.data.data.reason}`)
        history.push(`/trips/${tripId}`);
      } else if (response.data.success === false && response.data.message === "FAILED TO PAY FOR TRIP.") {
        alert("Something went wrong and it is our fault. Please contact our team for assistance")
      } else {
        alert("Something went wrong");
      }
    }).catch((err: any) => {
      console.error(err);
      alert("Paynow took too long to respond");
    });
  }

  const toggleDialog = () => {
    setHideDialog(!hideDialog);
  }

  React.useEffect(() => {
    const widget: any = window.cloudinary.createUploadWidget({
      cloudName: "projects-cloud",
      uploadPreset: "g9p0bzg5",
    },
      (error: any, result: any) => {
        if (result.info.url !== undefined) {
          handleDocumentUpload(result.info.url);
        }
      });

    widgetRef.current = widget;

  }, [tripId])

  const handlePaymentMethodChange = (e: FormEvent<HTMLDivElement>, option:IDropdownOption | undefined, i: any) => { 
    if(option) setPayload({...payload, paymentMethod : option?.key.toString() });
  } 

  const showWidget = () => {
    if (widgetRef.current) {
      // @ts-ignore: Object is possibly 'null'.
      widgetRef.current.open();
    }
  }

  return (
    <div className={style.wrapper}>
      <div className={style.grid}>
        <div className={style.card}>
          <h3 className={style.title}>{docUrl === "" ? "You will be redirected to PayNow to make a payment for your shipment" : "Your document has been uploaded, click \"Proceed\" to confirm your trip"} </h3>
          <div className={style.buttons}>
            <PrimaryButton text={docUrl === "" ? "Pay now" : "Change Document"} onClick={docUrl === "" ? toggleDialog : showWidget} /><DefaultButton text={docUrl === "" ? "Upload Authorised Quotation and Pay Later" : "Proceed"} onClick={docUrl === "" ? showWidget : confirmTrip} />
          </div>
        </div>
      </div>
      <Dialog hidden={hideDialog} isDarkOverlay={true} minWidth={500} onDismiss={toggleDialog} dialogContentProps={{ type: DialogType.normal, title: 'Edit Account Details' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <form>
          <Dropdown placeHolder="Select payment method" label="Payment method" onChange={handlePaymentMethodChange} options={paymentOptions}/>
          <TextField label="Phone Number" name="mobileNumber" placeholder="Phone Number" onChange={handleChange} />
          <DialogFooter>
            <PrimaryButton text="Pay" onClick={handlePayment}/>
            <DefaultButton text="Cancel" onClick={toggleDialog} />
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  )
}