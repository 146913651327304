import React, { useState, useRef } from "react";
import style from './header.module.css';
import { getTheme, mergeStyleSets, FontWeights, FocusTrapCallout, FocusZone, Stack, PrimaryButton, DefaultButton } from "office-ui-fabric-react";
import { LoginContext } from "../../App";

interface IHeaderProps {
  toggle?: any,
}

export interface ICalloutFocusTrapExampleState {
  isCalloutVisible: boolean;
}

// Themed styles for the example.
const theme = getTheme();
const styles = mergeStyleSets({
  buttonArea: {
    verticalAlign: 'top',
    display: 'inline-block',
    textAlign: 'center',
    margin: '0 100px',
    minWidth: 130,
    height: 32
  },
  callout: {
    maxWidth: 300
  },
  header: {
    padding: '18px 24px 12px'
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semilight
    }
  ],
  inner: {
    height: '100%',
    padding: '0 24px 20px'
  },
  actions: {
    position: 'relative',
    marginTop: 20,
    width: '100%',
    whiteSpace: 'nowrap'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px 24px'
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight
    }
  ]
});

export const Header = (props: IHeaderProps) => {
  const [callOutVisible, setCallOutVisible] = useState(false);
  const helpButtonRef = useRef(null);


  const toggleCallOut = () => {
    setCallOutVisible(!callOutVisible);
  }
  return (
    <header className={style.header}>
      <div>
        <h1 className={style.title} >Intransit</h1>
        <p className={style.tagline}>Powered by Thumeza</p>
      </div>
      <div>
        <button ref={helpButtonRef} onClick={toggleCallOut} className={style.logout}>
          Help
        </button>
        {callOutVisible ? (
          <div>
            <FocusTrapCallout role="alertdialog" className={styles.callout} gapSpace={0} target={helpButtonRef} onDismiss={toggleCallOut} setInitialFocus={true}>
              <div className={styles.header}>
                <p className={styles.title}>Having some Trouble</p>
              </div>
              <div className={styles.inner}>
                <div>
                  <p className={styles.subtext}>
                    Call or email our team and we will be happy to help.
                  </p>
                </div>
              </div>
              <FocusZone>
                <Stack className={styles.buttons} gap={8} horizontal>
                  <PrimaryButton href="mailto:help@thumeza.org.zw" >Email</PrimaryButton>
                  <PrimaryButton href="tel:+263773265854">Call</PrimaryButton>
                  <DefaultButton onClick={toggleCallOut}>Dismiss</DefaultButton>
                </Stack>
              </FocusZone>
            </FocusTrapCallout>
          </div>
        ) : null}
      </div>
      <div>
        <LoginContext.Consumer>
          {([token, setToken]) => {
            return (
              <button onClick={() => { localStorage.removeItem("token"); window.location.reload();  }} className={style.logout}>
                Logout
              </button>
              )
          }}
        </LoginContext.Consumer>
      </div>
    </header>
  )
}