import React, { useEffect, useState } from "react";
import { Map } from "../../components";
import { useParams, useHistory } from "react-router-dom";
import { request } from "../../helpers/request-helper";
import { DefaultButton, Stack, IStackStyles, Text, PrimaryButton } from "office-ui-fabric-react";
import { stackTokens, buttonStackTokens } from "../../util/stackTokens";

export const LocationView = () => {
  let history = useHistory();
  let { locationId } = useParams();
  const [location, setLocation] = useState({ id: null, name: "", description: null, city: null, streetAddress: null, locale: null, geoLocation: { coordinates: [-20.13527, 28.55708] } });

  const stackStyles: IStackStyles = {
    root: {
      maxWidth: 1200,
      display: "block"
    }
  };


  useEffect(() => {
    request.get(`user/locations/${locationId}`).then((response) => {
      if (response.data.success === true && response.data.message === "USER LOCATION FOUND.") {
        setLocation(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    });
  }, [locationId]);


  const deleteLocation = () => {
    request.delete(`user/locations/${locationId}`).then((response) => {
      if (response.data.success === true && response.data.message === "LOCATION DELETED.") {
        history.push("/locations");
      } else {
        console.error("Something went wrong");
      }
    });
  }

  const { name, description, geoLocation, streetAddress, locale, city } = location;
  return (
    <Stack horizontalAlign="center">
      <Stack styles={stackStyles} tokens={stackTokens}>      
        <Text variant="xxLarge">Location: {name} </Text>
        <div>
          <Text variant="mediumPlus">{description}</Text>
          <Text variant="mediumPlus">{streetAddress}, {locale}, {city}</Text>
        </div>
        <Map lat={geoLocation.coordinates[0]} lng={geoLocation.coordinates[1]} />
        <Stack horizontal tokens={buttonStackTokens}>
        <PrimaryButton text="View on Google Maps" href={`http://www.google.com/maps/place/${geoLocation.coordinates[0]},${geoLocation.coordinates[1]}`}/>
          <DefaultButton text="Delete Location" disabled onClick={deleteLocation} />
        </Stack>
      </Stack>
    </Stack>
  )
}