import { IStackTokens } from "office-ui-fabric-react";

export const buttonStackTokens: IStackTokens = {
  childrenGap: '15px',
  padding: '10px 0'
};

export const stackTokens: IStackTokens = {
  childrenGap: '40px',
};

export const topStackTokens: IStackTokens = {
  childrenGap: '15px',
};