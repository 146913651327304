import React from "react"
import style from "./layout.module.css";
import { Header, Navigation, } from "../components";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { LocationView, DriverTripView, DriverConfirmTripView, DriverHomeView, VehiclesView, DriverTripsView, DriverAccountView } from "../views";
import { LoginContext } from "../App";
import { IToken } from "../interfaces/Token";



const routes: any = [
  { path: "/driver/", name: "Home", exact: true },
  { path: "/driver/vehicles", name: "Vehicles" },
  { path: "/driver/trips", name: "Trips" },
  { path: "/driver/account", name: "Account" }
];

export const DriverLayout = () => {
  return (
    <div className={style.container}>
      <Header />
      <Navigation routes={routes} />
      <div className={style.wrapper}>
        <LoginContext.Consumer>
          {([token, setToken]) => {
            const _token = token as IToken;
            if (_token.user.type === "driver") {
              return (
                <main className={style.main}>
                  <Router>
                    <Switch>
                      <Route path="/locations/:id" component={LocationView} />
                      <Route path="/driver/vehicles" component={VehiclesView} />
                      <Route path="/driver/trips/:tripId" component={DriverTripView} />
                      <Route path="/driver/trips" component={DriverTripsView} />
                      <Route path="/driver/confirm-trip/:tripId" component={DriverConfirmTripView} />
                      <Route path="/driver/account" component={DriverAccountView} />
                      <Route path="/" component={DriverHomeView} />
                    </Switch>
                  </Router>
                </main>
              )
            } else {
              return(
              <main className={style.main}>
                <p>You Account is still under review and is yet to be verified</p>
              </main>
              )
            }
          }
        }
       </LoginContext.Consumer>

      </div>
    </div>
  )
}
