import React, { useState } from "react";
import style from "./pages.module.css";
import { AccountsHeader } from "../../components";
import { NavLink, useHistory } from "react-router-dom";
import { authRequest } from "../../helpers/request-helper";
import { PrimaryButton, DefaultButton, TextField, IStackTokens, Stack } from "office-ui-fabric-react";
import { MobileNumber } from "simple-regex";
import { formatNumber } from "../../helpers/formatNumber";

const fields: any = {
  firstName: "First Name",
  lastName: "Last Name",
}

export const DriverSignupPage = () => {
  const [payload, setPayload] = useState({ type: "driver", email: "", password: "" });

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  let history = useHistory();

  const signUp = () => {
    //@ts-ignore
   const phoneNumber: string = formatNumber(payload.phoneNumber);
    //@ts-ignore
   const whatsappNumber: string = formatNumber(payload.whatsappNumber);
   const {Econet, Netone, Telecel } = MobileNumber;
    const isValidNumber: boolean = Econet.test(phoneNumber) || Netone.test(phoneNumber) || Telecel.test(phoneNumber);
    const isValidWhatsAppNumber: boolean = Econet.test(whatsappNumber) || Netone.test(whatsappNumber) || Telecel.test(whatsappNumber);
    if (!isValidNumber) {
      alert("Please enter a valid Phone number");
      return;
    }
    if (!isValidWhatsAppNumber) {
      alert("Please enter a valid WhatsApp number");
      return;
    } 
    authRequest.post("/signup", payload).then((response) => {
      if (response.data.success === true && response.data.message === "REGISTRATION SUCCESSFUL.") {
        alert("Please verify your account to proceed with verification");
      } else if (response.data.success === false && response.data.message === "SIMILAR ACCOUNT ALREADY EXISTS.") {
        alert("Account already exists");
      } else if (response.data.success === false && response.data.message === "MISSING OR INVALID PARAMETERS.") {
        const missingDetails: string[] = response.data.data.missing;
        alert(`Please enter all the necessary details. Missing fields: ${missingDetails.map((field: string) => (fields[field])).join(', ')}`);
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      alert("Something went wrong");
    });
  }
  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: "10px 0"
  };
  return (
    <div className={style.wrapper}>
      <AccountsHeader />
      <form className={style.card}>
        <h2 className={style.heading}>Register as a Driver</h2>
        <Stack tokens={stackTokens}>
          <TextField label="First Name" placeholder="First Name" name="firstName" required onChange={handleChange} />
          <TextField label="Last Name" placeholder="Last Name" name="lastName" required onChange={handleChange} />
          <TextField label="Email" type="email" placeholder="Email Address" name="email" required onChange={handleChange} />
          <TextField label="Phone Number" placeholder="example +263773265854" name="phoneNumber" type="phone" onChange={handleChange} required />
          <TextField label="WhatsApp Number" type="tel" placeholder="example +263773265854" name="whatsappNumber"  onChange={handleChange} required />
          <TextField label="License Number" placeholder="License Number" name="licenseNumber" onChange={handleChange} required />
          <TextField label="National ID Number" placeholder="National ID Number" name="nationalId" onChange={handleChange} required />
          <TextField label="Password" placeholder="Password" name="password" onChange={handleChange} required />
          <div className={style.buttons}>
            <PrimaryButton text="Sign Up" onClick={signUp} /><DefaultButton text="Cancel" type="reset" />
          </div>
        </Stack>
        <p className={style.instruction}>Already have an account? <NavLink to="/login">Log in here.</NavLink> If you would like to resend verification email, <NavLink to="/resend-verification">click here</NavLink> </p>
      </form>
    </div>
  )
}