import React from "react";
import style from "./sub-navigation.module.css";
import { NavLink } from "react-router-dom";
import logo from "./intransit.png";
import facebook from "./facebook.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";

export const SubNavigation = () => {
  return (
    <nav className={style.wrapper}>
      <NavLink to="/"><img src={logo} className={style.logo} alt=""/> </NavLink> 
      <NavLink className={style.link} activeClassName={style.active} to="/data-policy">Data Policy</NavLink>    
      <NavLink className={style.link} activeClassName={style.active} to="/terms-and-conditions">Terms of Service</NavLink>
      <a href="https://www.linkedin.com/showcase/intransitafrica/" className={style.social}>
        <img src={linkedin} alt=""/>
      </a>      
      <a href="https://www.facebook.com/intransitafrica" className={style.social}>
        <img src={facebook} alt=""/>
      </a>
      <a href="https://twitter.com/Intransitafrica" className={style.social}>
        <img src={twitter} alt=""/>
      </a>


    </nav>
  )
}