import React from "react";
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from "react-google-maps"


export const MapComponent = withScriptjs(withGoogleMap((props: any)=>      
  <GoogleMap defaultZoom={12} defaultCenter={{ lat: props.lat, lng: props.lng}} options={{ disableDefaultUI: true}}>
    <Marker position={{ lat: props.lat, lng: props.lng }} />
  </GoogleMap>
));

export const Map = (props: any) => <MapComponent
isMarkerShown
lat = {props.lat} 
lng = {props.lng}
googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB1L4yicVAZkUhC2cA-lBQfp3nZ47_RDtA&v=3.exp&libraries=geometry,drawing,places"
loadingElement={<div style={{ height: `100%` }} />}
containerElement={<div style={{ height: `400px`, }} />}
mapElement={<div style={{ height: `100%`, borderRadius: 6  }} />}
/>