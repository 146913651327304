import React, { useState, useEffect } from "react";
import { request } from "../../helpers/request-helper";
import { IColumn, ShimmeredDetailsList, SelectionMode, PrimaryButton, Text, Stack } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toCurrency } from "../../helpers/currency";
import { LoginContext } from "../../App";
import { IToken } from "../../interfaces/Token";
import { buttonStackTokens, stackTokens, topStackTokens } from "../../util/stackTokens";


export const UserHomeView = () => {
  let init: any[] = []
  const [pendingTrips, setPendingTrips] = useState(init);
  const [quotations, setQuotations] = useState(init);
  const [ongoingTrips, setOngoingTrips] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();


  const tripColumns: IColumn[] = [{
    key: 'deliveryDate',
    name: 'Shipping Date',
    fieldName: 'deliveryDate',
    minWidth: 50,
    maxWidth: 140,
    onRender: (item: any) => {
      const date = moment.utc(item.deliveryDate).format("D MMMM YYYY");
      return date;
    }
  }, {
    key: 'sourceColumn',
    name: 'Source',
    fieldName: 'pickupLocation',
    minWidth: 80,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.pickupLocation.name;
    }
  }, {
    key: 'destinationColumn',
    name: 'Destination',
    fieldName: 'dropoffLocation',
    minWidth: 80,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.dropoffLocation.name;
    }
  }, {
    key: 'nameColumn',
    name: 'Delivery Name',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 80,
  }, {
    key: 'costColumn',
    name: 'Cost',
    fieldName: 'cost',
    minWidth: 80,
    onRender: (item: any) => {
      return toCurrency(item.cost);
    }
  }];


  useEffect(() => {
    request.get("user/trips").then((response) => {
      if (response.data.success === true && response.data.message === "USER TRIPS FOUND.") {
        const trips = response.data.data.trips;
        setQuotations(trips.filter((trip: any) => { return trip.status === "CREATED" }));
        setPendingTrips(trips.filter((trip: any) => { return trip.status === "PENDING" }));
        setOngoingTrips(trips.filter((trip: any) => { return trip.status === "ACCEPTED" }));
      }
        setIsLoading(false);
    }).catch(error => {
        console.warn(error);
        alert("Something went wrong. Please check your internet connection.");
    });
  }, []);

  const _onQuotationInvoked = (item: any): void => {
    history.push(`confirm-trip/${item.id}`)
  }

  const _onTripInvoked = (item: any): void => {
    history.push(`trips/${item.id}`)
  }


  return (
    <LoginContext.Consumer>
      {([token, setToken]) => {
        const _token = token as IToken;
        return (
          <Stack tokens={stackTokens}>
            <Stack tokens={topStackTokens}>
              <Text variant="xxLarge">Good Day {_token.user.name}</Text>
                    <Text variant="mediumPlus">You have {ongoingTrips.length > 0 ? ongoingTrips.length : "no"} ongoing {ongoingTrips.length == 1 ? "shipment" : "shipments"}, {pendingTrips.length > 0 ? pendingTrips.length : "no"} {pendingTrips.length == 1 ? "shipment" : "shipments"} awaiting drivers and {quotations.length > 0 ? quotations.length : "no"} {quotations.length == 1 ? "quotation" : "quotations"} awaiting approval. {ongoingTrips.length > 0 && pendingTrips.length > 0 && quotations.length > 0 ? "Create a new quotation to get started." : ""}</Text>
              <Stack horizontal tokens={buttonStackTokens}>
                <PrimaryButton text="New Quotation" onClick={() => history.push("/request-quotation")} />
                <PrimaryButton text="See all Shipments" onClick={() => history.push("/trips")} />
              </Stack>
            </Stack>
            <div>
              {ongoingTrips.length > 0 || isLoading ?
                <React.Fragment>
                  <Text variant="large">Ongoing Shipments</Text>
                  <ShimmeredDetailsList selectionMode={SelectionMode.none} items={ongoingTrips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onTripInvoked} />
                </React.Fragment>
                : null
              }
            </div>

            <div>

              {pendingTrips.length > 0 || isLoading ?
                <React.Fragment>
                  <Text variant="large">Pending Trips</Text>
                  <ShimmeredDetailsList selectionMode={SelectionMode.none} items={pendingTrips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onTripInvoked} />
                </React.Fragment> : null
              }
            </div>
            <div>

              {quotations.length > 0 || isLoading ?
                <React.Fragment>
                  <Text variant="large">Quotations</Text>
                  <ShimmeredDetailsList selectionMode={SelectionMode.none} items={quotations} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onQuotationInvoked} />
                </React.Fragment>
                : null
              }
            </div>
          </Stack>
        )
      }}
    </LoginContext.Consumer>
  )
}
