import React from "react"
import style from "./layout.module.css";
import { Header, Navigation } from "../components";
import { ClientAccountView, UserHomeView, TripsView, LocationsView, TripView, AddLocation, EstimateView, ConfirmTripView, LocationView, PromptPaymentView } from "../views";
import { HashRouter as Router, Switch, Route } from "react-router-dom";




const routes: any = [
  { path: "/", name: "Home", exact: true },
  { path: "/locations", name: "Locations" },
  { path: "/trips", name: "Shipments" },
  { path: "/account/", name: "Account" },
];

export const UserLayout = () => {
  return (
    <div className={style.container}>
      <Header />
      <Navigation routes={routes} />
      <div className={style.wrapper}>
        <main className={style.main}>
          <Router>
            <Switch>
              <Route path="/locations/add" exact component={AddLocation} />
              <Route path="/locations/:locationId" component={LocationView} />
              <Route path="/locations" component={LocationsView} />
              <Route path="/trips/:tripId" component={TripView} />
              <Route path="/trips" component={TripsView} />
              <Route path="/payment-prompt/:tripId" component={PromptPaymentView} />
              <Route path="/request-quotation" component={EstimateView} />
              <Route path="/confirm-trip/:tripId" component={ConfirmTripView} />
              <Route path="/account" component={ClientAccountView} />
              <Route path="/" component={UserHomeView} />
            </Switch>
          </Router>
        </main>
      </div>
    </div>
  )
}