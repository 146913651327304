import axios from 'axios';

const api_endpoint = "https://api.intransit.co.zw/";
const auth_endpoint = `${api_endpoint}auth`;

export const request = axios.create({
  baseURL: api_endpoint,
  timeout: 8000,
  headers: {
    "token": localStorage.token, 
    "Content-Type": "application/json",
  }
});

export const authRequest = axios.create({
  baseURL: auth_endpoint, 
  timeout: 8000, 
  headers: {
    "Content-Type": "application/json",
  }
})