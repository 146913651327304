import React, { useState } from 'react';
import "./App.css";
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { LoginPage, SignupPage, ForgotPasswordPage, RecoveryPasswordPage, UserSignupPage, TermsAndConditions, DataPolicy, NewPasswordPage } from './pages';
import { DriverSignupPage } from './pages/authentication/driver-signup';
import { decode } from 'jsonwebtoken';
import { Layout } from './components/layout/layout';
import { IToken } from './interfaces/Token';
import { ResendVerification } from './pages/authentication/resend-verification';

export const LoginContext = React.createContext([{}, () => {}]);

export const App = () => {     
  const foo = decode(localStorage["token"]);
  const _token = foo as IToken;
  const [token, setToken] = useState(_token);

  return (
    <LoginContext.Provider value={[token, setToken]}>
      <Router>
        <Switch>
          <Route path="/resend-verification" component={ResendVerification}/>
          <Route path="/data-policy" component={DataPolicy} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/recovery-instructions" component={RecoveryPasswordPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/change-password/:code" component={NewPasswordPage} />
          <Route path="/driver-signup" component={DriverSignupPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/user-signup" component={UserSignupPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/" component={Layout} />
        </Switch>
      </Router>
    </LoginContext.Provider>
  );
}
