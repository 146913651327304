import React, { useState, useEffect } from "react";
import { SectionHeading } from "../../components";
import style from "./drivers.module.css";
import { IColumn, ShimmeredDetailsList, SelectionMode, Text } from "office-ui-fabric-react";
import { request } from "../../helpers/request-helper";
import { useHistory } from "react-router-dom";

export const DriversView = () => {
  const init: any[] = [];
  const [drivers, setDrivers] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  
  const columns: IColumn[] = [{
    key: 'nameColumn',
    name: 'User Name',
    fieldName: 'name',
    minWidth: 100,
  }, {
    key: 'emailColumn',
    name: 'Email',
    fieldName: 'email',
    minWidth: 200,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 200,
  },
  {
    key: 'typeColumn',
    name: 'User Type',
    fieldName: 'type',
    minWidth:100
  }, 
  ];


  useEffect(() => {
      request.get("/admin/users").then((response) => {
          if (response.data.success === true && response.data.message === "ALL USERS FOUND.") {
              setDrivers(response.data.data.users.filter((user: any) => {return user.type === "driver"}));
              setIsLoading(false)
          } else {
              console.error("Something went wrong");
          }
      });
  }, []);

  const _onItemInvoked = (item: any): void => {
    history.push(`drivers/${item.id}`);
  }

return (
  <div>
    <div>
      <SectionHeading title="Drivers" />
      <Text variant="small" block>UNVERIFIED: Driver registered but has not verified their email address. INACTIVE: Account has been deactivated by an admin and driver cannot access trips. VERIFIED: Driver registered, verified their email and can accept trips. (This setting may be changed from the driver profile)</Text>
    </div>

    <div className={style.cards}>
    {drivers ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={drivers} enableShimmer={isLoading} columns={columns} onItemInvoked={_onItemInvoked} /> : null}
    </div>
  </div>
)
}