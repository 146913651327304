import React, { useState } from "react";
import style from "./add-location.module.css"
import { LocationInput } from "../../components";
import { request } from "../../helpers/request-helper";
import { useHistory } from "react-router-dom";
import { DefaultButton, PrimaryButton, TextField, IStackTokens, Stack } from "office-ui-fabric-react";

export const AddLocation = () => {
  let history = useHistory();
  const [payload, setPayload] = useState({});
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const addLocation = () => {
    const coordinates = [latitude, longitude];
    if (latitude && longitude) {
      request.post("user/locations", {coordinates: coordinates, ...payload}).then((response) => {
          if (response.data.success === true && response.data.message === "LOCATION CREATED.") {
              alert("Location has been added");
              history.push("/locations");
          } else if (response.data.success === false && response.data.message === "INVALID TOKEN") {
              alert("ERROR! You do not have permission to do that")
          } else if (response.data.success === false && response.data.message === "MISSING OR INVALID LOCATION PARAMETERS.") {
              alert("Some details are missing.");
          }
        });
    } else {
      alert("Please move the marker to pick your location on the map")
    }
  }

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: "10px 0"
  };
  
  return (
    <div>    
        <form className={style.grid}>
          <h3>New Location</h3>
          <Stack tokens={stackTokens}>
          <LocationInput setLatitude={setLatitude} setLongitude={setLongitude} />
          <TextField label="Location Name" name="name" placeholder="Name of the delivery location" onChange={handleChange} />
          <TextField label="Address Line 1" placeholder="Street Name" name="streetAddress" onChange={handleChange} />
          <TextField label="Address Line 2" name="locale" placeholder="Surburb" onChange={handleChange} />
          <TextField label="City" name="city" placeholder="City" onChange={handleChange} />
          <TextField label="Additional Description*" name="description" multiline autoAdjustHeight placeholder="Instructions for driver deliverying to this location..." onChange={handleChange} />
          <div className={style.buttons}>
            <PrimaryButton text="Save Location" primary onClick={addLocation} /><DefaultButton text="Clear" />
          </div>
          </Stack>
        </form>
    </div>
  )
}