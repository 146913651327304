import React, { useState, useEffect } from "react";
import style from "./account-view.module.css";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, Dialog, DialogType, TextField, DialogFooter, DefaultButton, Separator, Stack, } from "office-ui-fabric-react";

export const ClientAccountView = () => {
  const [hideDialog, setHideDialog] = useState(true);
  const [profile, setProfile] = useState({ firstName: "", lastName: "", jobTitle: "", type: "", companyName: "", billingAddress: "", streetAddress:"", locale:"", city: "", country:"",  phoneNumber: "", businessPartnerNumber: "", paymentTerms: "" });
  const [payload, setPayload] = useState({});

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    request.get(`user/profile`).then((response) => {
      if (response.data.success === true && response.data.message === "PROFILE FOUND.") {
        setProfile(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    });
  }, []);

  const editAccount = () => {
    request.put("user/profile", payload).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Profile Updated");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    })
  }

  const toggleDialog = () => {
    setHideDialog(!hideDialog);
  }

  const { firstName, lastName, jobTitle, type, companyName, phoneNumber, streetAddress, locale, city, businessPartnerNumber, paymentTerms} = profile;
  return (
    <div className={style.card}>
      <header className={style.header}>
        <div className={style.details}>
          <h2 className={style.name}>{ type === 'organisation'? companyName :`${firstName} ${lastName}`}</h2>
          <p className={style.type}>{type} account</p>
         { type === 'organisation'? null : <p className={style.value}> {jobTitle} {companyName && companyName !== ""? `at ${companyName}`: null}</p> }
          <address className={style.address}>
            {streetAddress}<br/>
            {locale}<br/>
            {city}
          </address>
          <div className={style.settings}>
            <div className={style.setting}>
            {type === "organisation" ? <p className={style.value}>Business Partner Number: {businessPartnerNumber}</p> : null}
                <p className={style.value}>Phone Number: {phoneNumber}</p>
            </div>
          </div>
        </div>
      </header>
      <Stack horizontal tokens={{ childrenGap: 15 }} style={{ marginTop: 20 }} >
        <PrimaryButton text="Edit Details" onClick={toggleDialog} />
      </Stack>
      <Dialog hidden={hideDialog} isDarkOverlay={true} minWidth={500} onDismiss={toggleDialog} dialogContentProps={{ type: DialogType.normal, title: 'Edit Account Details' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <form>
          <TextField label="Phone Number" name="phoneNumber" defaultValue={phoneNumber} onChange={handleChange} />
          <Separator>Billing Address</Separator>
          <TextField label="Address Line 1" defaultValue={streetAddress} name="streetAddress" onChange={handleChange} />
          <TextField label="Address Line 2" name="locale" defaultValue={locale} onChange={handleChange} />
          <TextField label="City" name="city" defaultValue={city} onChange={handleChange} />
          {type === "organisation" ? <TextField label="Payment Terms" name="paymentTerms" defaultValue={paymentTerms} onChange={handleChange} multiline autoAdjustHeight/> : null}
          <DialogFooter>
            <PrimaryButton text="Save Changes" onClick={editAccount} />
            <DefaultButton text="Cancel" onClick={toggleDialog} />
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  )
}
