import React, { useState, useEffect } from "react";
import { Stack, IStackStyles, Text, PrimaryButton } from "office-ui-fabric-react";
import { request } from "../../helpers/request-helper";
import { useParams } from "react-router-dom";
import moment from "moment";
import { topStackTokens, stackTokens, buttonStackTokens } from "../../util/stackTokens";

const statusText: any = {
  ACCEPTED: "You have accepted the trip.",
  DISPATCHED: "You have collected the trip from dispatch.",
  CANCELLED: "This trip was cancelled",
  DELIVERED: "You delivered this shipment."
}

export const DriverTripView = () => {
  const [trip, setTrip] = useState();
  let { tripId } = useParams();

  const stackStyles: IStackStyles = {
    root: {
      maxWidth: 1200,
      display: "flex"
    }
  };

  useEffect(() => {
    request.get(`driver/trips/${tripId}`).then((response) => {
      if (response.data.success === true && response.data.message === "TRIP FOUND.") {
        setTrip(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    });

  }, [tripId]);

  const confirmCollection = () => {
    request.post(`driver/trips/${tripId}/status`, { "status": "DISPATCHED" }).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Goods marked as dispatched");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    });
  }

  const confirmDelivery = () => {
    request.post(`driver/trips/${tripId}/status`, { "status": "DELIVERED" }).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Goods marked as delivered");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    });
  }


  if (!trip) {
    return <p>Loading</p>
  } else {
    const { status, load, deliveryDate, dropoffLocation, name, pickupLocation, cost, currency } = trip;
    const p = pickupLocation;
    const d = dropoffLocation;
    const date = moment.utc(deliveryDate).format("D MMMM YYYY");

    return (
      <Stack horizontalAlign="center">
        <Stack styles={stackStyles} tokens={stackTokens}>
          <Stack tokens={topStackTokens}>
            <Text variant="xxLarge">Shipment: {name} </Text>
            <Text variant="mediumPlus">({load.tonnage} {load.tonnage === 1 ? "tonne" : "tonnes"})</Text>
            {load.image ? <img style={{ width: "80%" }} src={load.image} alt="" /> : null}
            <Text variant="mediumPlus">Status: {statusText[status]} (Expected Delivery date is {date})</Text>
            {status === "ACCEPTED" || status === "DISPATCHED" ?
              <Stack horizontal tokens={buttonStackTokens}>
                <PrimaryButton text={status === "ACCEPTED" ? "Confirm Collection" : "Confirm Delivery"} onClick={status === "ACCEPTED" ? confirmCollection : confirmDelivery} />
              </Stack> : null
            }
          </Stack>
          <div>
         
            <Text variant="mediumPlus" block>Load Collection point: {p.name} ({p.streetAddress}, {p.locale}, {p.city})</Text>
            <Text variant="mediumPlus" block>Load Dropoff point: {d.name} ({d.streetAddress}, {d.locale}, {d.city})</Text> 
            <Text variant="mediumPlus">{load.description}</Text>
          </div>
          <div>
            <Text variant="medium" block>Your Trip Cost: {currency} {cost}</Text>
          </div>
        </Stack>
      </Stack>
    )
  }
}