import React from "react"
import { AccountsHeader  } from "../../components"
import style from "./pages.module.css";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "office-ui-fabric-react";


export const TermsAndConditions= () => {
  let history = useHistory();
  return (
    <div className={style.wrapper}>
      <AccountsHeader/>
      <div className={style.page}> 
        <h2 className={style.heading}>Terms and Conditions to providing service on Thumeza’s services.</h2> 
        <div>
          <h2>1. The Services</h2>
          <p>The Services comprises a platform for clients with delivery needs to be connected to logistics operators referred to as “Partner Drivers”, which enable users to arrange and schedule logistics and/or delivery services and/or to purchase certain goods, including with third party providers of such services and goods under agreement with Thumeza or certain of Thumeza’s affiliates (“Third Party Providers”). In certain instances the Services may also include an option to receive logistics and/or delivery services for an upfront price, subject to acceptance by the respective Third Party Providers. You acknowledge that your ability to obtain logistics and or delivery services through the use of Thumeza does not establish Thumeza as a provider or logistics or delivery services. </p>
          <h2>2. Provision of Services</h2>
          <p>You acknowledge that portions of the Services may be made available under Thumeza’s various brands or request options associated with transportation or logistics, including the brands currently referred to as “Thumeza Last Mile,” and “Intransit”. You also acknowledge that the Services may be made available under such brands or request options by or in connection with: (i) certain of Thumeza’s subsidiaries and affiliates; or (ii) independent Third Party Providers or holders of similar transportation permits, authorizations or licenses.</p>
          <h2>3. Payment Terms</h2>
          <h3>Individual Users</h3>
          <ol>
            <li>You understand that use of the Services may result in charges to you for the services or goods you receive (“Charges”). Thumeza will receive and/or enable your payment of the applicable Charges for services or goods obtained through your use of the Services.</li>
            <li>You acknowledge that a quotation unless accepted within a period of 24 hours upon being proffered is rendered invalid and will have to be requoted in order to be translated into an invoice.</li>
            <li>As a non-enterprise user of these services, payment will be required upfront prior to any services being availed to you. Should you require credit facilities, please reach out to <a href="mailto:payments@thumeza.org.zw">payments@thumeza.org.zw</a></li>
            <li>Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges including a booking fee, municipal tolls, airport surcharges or processing fees for split payments. All Charges and payments will be enabled by Thumeza using the preferred payment method designated in your Account, after which you will receive a receipt by email. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that Thumeza may use a secondary payment method in your Account, if available. Charges paid by you are final and non-refundable, unless otherwise determined by Thumeza. </li>
            <li>As between you and Thumeza, Thumeza reserves the right to establish, remove and/or revise Charges for any or all services or goods obtained through the use of the Services at any time in Thumeza’s sole discretion. </li>
            <li>Further, you acknowledge and agree that Charges applicable in certain geographical areas may increase substantially during times of high demand. Thumeza will use reasonable efforts to inform you of Charges that may apply, provided that you will be responsible for Charges incurred under your Account regardless of your awareness of such Charges or the amounts thereof. </li>
          </ol>	
          <h3>Enterprise Clients</h3>
          <ol>
            <li>You understand that use of the Services may result in charges to you for the services or goods you receive (“Charges”). Thumeza will receive and/or enable your payment of the applicable Charges for services or goods obtained through your use of the Services.</li>
            <li>You acknowledge that a quotation unless accepted within a period of 24 hours upon being proffered is rendered invalid and will have to be requoted in order to be translated into an invoice. </li>
            <li>You acknowledge that invoices upon submission are due for payment within a period of 7 business days.</li>
            <li>Should the initial 7 days’ lapse without payment, an invoice will be reissued with a 10% interest penalty per week.</li>
            <li>Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges including a booking fee, municipal tolls, airport surcharges or processing fees for split payments. All Charges and payments will be enabled by Thumeza using the preferred payment method designated in your Account, after which you will receive a receipt by email. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that Thumeza may use a secondary payment method in your Account, if available. Charges paid by you are final and non-refundable, unless otherwise determined by Thumeza.</li>
            <li>As between you and Thumeza, Thumeza reserves the right to establish, remove and/or revise Charges for any or all services or goods obtained through the use of the Services at any time in Thumeza’s sole discretion.</li>
            <li>Further, you acknowledge and agree that Charges applicable in certain geographical areas may increase substantially during times of high demand. Thumeza will use reasonable efforts to inform you of Charges that may apply, provided that you will be responsible for Charges incurred under your Account regardless of your awareness of such Charges or the amounts thereof. </li>
          </ol>
          <h2>4. Non Liability of the Company</h2>
          <p>All services and carriage rendered Thumeza in terms of these conditions shall be at the risk of the client which shall mean that Thumeza shall not be liable for any loss of any nature whatsoever which the client may suﬀer as a result of any cause whatsoever, it is speciﬁcally recorded without prejudice to the generality thereof that the client acknowledges that the transport and/or storing of merchandise and/or any other service undertaken by Thumeza for the client in terms of these conditions shall be undertaken on the speciﬁc condition that Thumeza shall not be liable for any damage, shortage or delay due to or caused by any negligence, whether gross or otherwise, on the part of Thumeza or its employees or its agents or its assigns or from any other cause whatsoever. Furthermore, if notwithstanding the provisions hereof, any absolute or strict liability may be deemed to arise at any time against Thumeza as a carrier under the common law prevailing in Zimbabwe any such liability is in terms hereof explicitly excluded in respect of any service or carriage of goods provided or rendered by the Thumeza.</p>
          <h2>5. Legal Jurisdiction</h2>
          <p>These Terms are governed by and construed in accordance with the laws of Zimbabwe, without giving effect to any conflict of law principles, except as may be otherwise provided in the Arbitration Agreement above or in supplemental terms applicable to your region. However, the choice of law provision regarding the interpretation of these Terms is not intended to create any other substantive right to non-Zimbabweans to assert claims under Zimbabwe law whether that be by statute, common law, or otherwise. These provisions, are only intended to specify the use of Zimbabwe law to interpret these Terms and the forum for disputes asserting a breach of these Terms, and these provisions shall not be interpreted as generally extending Zimbabwe law to you if you do not otherwise reside in Zimbabwe</p>
        </div>
        <PrimaryButton onClick={() => history.goBack()}>Back</PrimaryButton>
      </div>
    </div>
  )
}