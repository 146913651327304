import React, { useState } from "react";
import style from "./pages.module.css";
import { MobileNumber} from "simple-regex";
import { AccountsHeader } from "../../components";
import { NavLink, Redirect } from "react-router-dom";
import { authRequest } from "../../helpers/request-helper";
import { PrimaryButton, DefaultButton, TextField, Checkbox, Dropdown, IStackTokens, Stack } from "office-ui-fabric-react";
import { LoginContext } from "../../App";
import { formatNumber } from "../../helpers/formatNumber";

const fields :any = {
    firstName: "First Name",
    lastName: "Last Name", 
}

export const UserSignupPage = () => {
  const [payload, setPayload] = useState({ type : "client"});

  const handleChange = (e: any) => {
      setPayload({ ...payload, [e.target.name]: e.target.value });
  }

  const signUp = () => {
    //@ts-ignore
    const phoneNumber: string = formatNumber(payload.phoneNumber);
    const {Econet, Netone, Telecel } = MobileNumber;
    const isValidNumber: boolean = Econet.test(phoneNumber) || Netone.test(phoneNumber) || Telecel.test(phoneNumber);
    if (!isValidNumber) {
      alert("Please enter a valid Phone Number");
      return;
    }
    authRequest.post("signup", payload).then((response) => {
        if (response.data.success === true && response.data.message === "REGISTRATION SUCCESSFUL.") {
            alert("Please verify your account to proceed with registration");
            
        } else if (response.data.success === false && response.data.message === "SIMILAR ACCOUNT ALREADY EXISTS.") {
            alert("Account already exists");
        } else if (response.data.success === false && response.data.message === "MISSING OR INVALID PARAMETERS.") {
            const missingDetails: string[] = response.data.data.missing;
            alert(`Please enter all the necessary details. Missing fields: ${missingDetails.map((field: string) => (fields[field] )).join(', ')}`);
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
        alert("Could not connect to the server.");
    });
  }
  
  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: "10px 0"
  };

  return (
    <LoginContext.Consumer>
      {([token, setToken ]) => {
        if (token) {
          return <Redirect to="/" />
        } else {
          return (
            <div className={style.wrapper}>
              <AccountsHeader />
              <div className={style.container}>
                <form className={style.card}>
                  <h2 className={style.heading}>Signup to Intransit</h2>
                  <Stack tokens={stackTokens}>
                  <TextField label="First Name" placeholder="First Name" name="firstName" onChange={handleChange} />
                  <TextField label="Last Name" placeholder="Last Name" name="lastName" onChange={handleChange} />
                  <TextField label="Job Title" placeholder="Job Title" name="jobTitle" onChange={handleChange} />
                  <TextField label="Phone Number" placeholder="example +263773265854" name="phoneNumber" type="phone" onChange={handleChange} />
                  <TextField label="Email Address" placeholder="Email Address" type="email" name="email" onChange={handleChange} />
                  <TextField label="Business Partner Number" placeholder="Business Partner Number" name="businessPartnerNumber" onChange={handleChange} />
                  <TextField label="Password" placeholder="Password" type="password" name="password" onChange={handleChange} />
                  <TextField label="Company" placeholder="Company Name" name="companyName" onChange={handleChange} />
                  <Dropdown label="Country" placeholder="Zimbabwe" options={[]} disabled={true} />
                  <div style={{ marginTop: 15 }}>
                    <Checkbox label="This is an organisation account" name="isOrganisation" onChange={handleChange} />
                  </div>
                  <div className={style.buttons}>
                    <PrimaryButton text="Sign Up" onClick={signUp} /><DefaultButton text="Cancel" type="reset" />
                  </div>
                  </Stack>
                  <p className={style.instruction}>Already have an account? <NavLink to="/login">Log in here</NavLink> or <NavLink to="/driver-signup">Register as a Driver</NavLink>. If you would like to resend verification email, <NavLink to="/resend-verification">click here</NavLink> </p>
                </form>
              </div>
            </div>
          )
        }
      }}
    </LoginContext.Consumer>
  )
}