import React from "react";
import { LoginContext } from "../../App"
import { UserLayout, AdminLayout, DriverLayout } from "../../layouts";
import { Redirect } from "react-router-dom";
import { IToken } from "../../interfaces/Token";

export const Layout = () => {
  return (
     <LoginContext.Consumer>
       {([token , setToken]) => {
         const _token = token as IToken;

         if(!token) {
          return <Redirect to="/login"/>
         } else if( _token.user.type === "client"  ){
           return <UserLayout/>
         } else if ( _token.user.type === "admin" || _token.user.type === "superadmin" ){
           return <AdminLayout/>
         } else if ( _token.user.type === "driver") {
           return <DriverLayout/>
         } else {
           return <Redirect to="/login"/>
         }    
       }}
      </LoginContext.Consumer> 
  )
}