import React, { useState, useEffect } from "react";
import style from "./account-view.module.css";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, Dialog, DialogType, TextField, DialogFooter, DefaultButton, Stack } from "office-ui-fabric-react";


export const DriverAccountView = () => {
  const [hideDialog, setHideDialog] = useState(true);
  const [profile, setProfile] = useState({firstName:"", lastName:"", phoneNumber:"", whatsappNumber:"", nationalId:"", licenseNumber:"" });
  const [payload, setPayload] = useState({});

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    request.get(`driver/profile/`).then((response) => {
       if (response.data.success === true && response.data.message === "DRIVER FOUND.") {
         setProfile(response.data.data);
       } else {
         console.error("Something went wrong");
       }
     });
  }, []);

  const editAccount = () => {
    request.put("driver/profile", payload).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("done")
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    })
  }

  const toggleDialog = () => {
    setHideDialog(!hideDialog);
  }

  const { firstName, lastName, phoneNumber, whatsappNumber, nationalId, licenseNumber } = profile;
  return (
    <div className={style.card}>
      <header className={style.header}>
        <div className={style.details}>
          <h2 className={style.name}>{firstName} {lastName}</h2>
          <div className={style.settings}>
            <div className={style.setting}>
              <div>
                <p className={style.value}>Phone Number: {phoneNumber}</p>
                <p className={style.value}>WhatsApp Number: {whatsappNumber}</p>
                <p className={style.value}>License Number: {licenseNumber}</p>
                <p className={style.value}>National ID Number: {nationalId}</p>
              </div>
            </div>
          </div>

        </div>
      </header>          
      <Stack horizontal tokens={{childrenGap: 20}} style={{ marginTop: 20 }} >
        <PrimaryButton text="Edit Details" onClick={toggleDialog} />
      </Stack>
      <Dialog hidden={hideDialog} isDarkOverlay={true} minWidth={500} onDismiss={toggleDialog} dialogContentProps={{ type: DialogType.normal, title: 'Edit Account Details' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <TextField label="Phone Number" name="phoneNumber" defaultValue={phoneNumber} onChange={handleChange} />
        <TextField label="WhatsApp Phone Number" name="whatsappNumber" defaultValue={whatsappNumber} onChange={handleChange} />
        <DialogFooter>
          <PrimaryButton text="Save Changes" onClick={editAccount} />
          <DefaultButton text="Cancel" onClick={toggleDialog} />
        </DialogFooter>
      </Dialog>
    </div>
  )
}
