import React, { useEffect, useState } from "react";
import * as moment from 'moment';
import { request } from "../../helpers/request-helper";
import { IColumn, ShimmeredDetailsList, SelectionMode, PrimaryButton, Stack, Text } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { toCurrency } from "../../helpers/currency";
import { stackTokens, topStackTokens, buttonStackTokens } from "../../util/stackTokens";

export const TripsView = () => {
  const init: any[] = [];
  const [trips, setTrips] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  const tripColumns: IColumn[] = [{
    key: 'deliveryDate',
    name: 'Shipping Date',
    fieldName: 'deliveryDate',
    minWidth: 50,
    maxWidth: 140,
    onRender: (item: any) => {
      const date = moment.utc(item.deliveryDate).format("D MMMM YYYY");
      return date;
    }
  }, {
    key: 'sourceColumn',
    name: 'Source',
    fieldName: 'pickupLocation',
    minWidth: 80,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.pickupLocation.name;
    }
  }, {
    key: 'destinationColumn',
    name: 'Destination',
    fieldName: 'dropoffLocation',
    minWidth: 80,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.dropoffLocation.name;
    }
  }, {
    key: 'nameColumn',
    name: 'Delivery Name',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 80,
  }, {
    key: 'costColumn',
    name: 'Cost',
    fieldName: 'cost',
    minWidth: 80,
    onRender: (item: any) => {
      return toCurrency(item.cost);
    }
  }];


  const _onItemInvoked = (item: any): void => {
    history.push(`trips/${item.id}`)
  }

  useEffect(() => {
    request.get("user/trips").then((response) => {
      if (response.data.success === true && response.data.message === "USER TRIPS FOUND.") {
        let _trips = response.data.data.trips;
        setTrips(_trips.filter((item: any) => { return !(item.status === "CANCELLED" || item.status === "CREATED") }));
        setIsLoading(false)
      } else {
        console.error("Something went wrong");
      }
    });
  }, []);

  return (
    <Stack tokens={stackTokens}>
      <Stack tokens={topStackTokens}>
        <Text variant="xLarge">Shipments</Text>
        <Text variant="mediumPlus">Your shipment history</Text>
        <Stack horizontal tokens={buttonStackTokens}>
          <PrimaryButton text="Request Shipment" onClick={() => history.push("/request-quotation")} />
        </Stack>
      </Stack>
      <div>
        {trips.length > 0 || isLoading ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={trips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onItemInvoked} /> : <Text variant="mediumPlus"></Text>
        }
      </div>
    </Stack>
  )
}