import React, { useState, useEffect } from "react"
import style from "./confirm-trip.module.css";
import { useParams, useHistory } from "react-router-dom";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, DefaultButton, IColumn, ShimmeredDetailsList, SelectionMode, Text} from "office-ui-fabric-react";
import logo from "./intransit.png";
import * as moment from 'moment';
import { toCurrency } from "../../helpers/currency";


export const ConfirmTripView = () => {
  const [trip, setTrip] = useState({name:"",deliveryDate:"", cost: 0, currency:"USD",load:{ description:"",tonnage:0},pickupLocation:{locale:"",name:"",streetAddress:"",city:""},dropoffLocation:{description:"",locale:"",name:"",streetAddress:"",city:""},vehicleType:{name:""}});

  const [isLoading, setIsLoading] = useState(true);
  let { tripId } = useParams();
  const [profile, setProfile] = useState({ firstName: "", lastName: "", jobTitle: "", type: "", companyName: "", billingAddress: "", streetAddress: "", locale: "", city: "", country: "", phoneNumber: "" });
  let history = useHistory();

  const itemsColumns: IColumn[] = [{
    key: 'name',
    name: 'Items',
    fieldName: 'name',
    minWidth: 50,
    onRender: (item: any) => {
      return (
        <div>
          <p><strong>{item.name}</strong></p>
          <p>{item.description}</p>
        </div>
      )
    }
  }, {
    key: 'quantity',
    name: 'Quantity',
    fieldName: 'quantity',
    minWidth: 100,
    maxWidth: 160,
  }, {
    key: 'price',
    name: 'Price',
    fieldName: 'price',
    minWidth: 100,
    maxWidth: 160,
  }, {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 100,
    maxWidth: 160,
  }];

  useEffect(() => {
    request.get(`user/trips/${tripId}`).then((response) => {
      if (response.data.success === true && response.data.message === "USER TRIP FOUND.") {
        const _trip = response.data.data;
        setTrip({..._trip});
        setIsLoading(false);
      } else {
        console.error("Something went wrong");
      }
    });
    request.get(`user/profile`).then((response) => {
      if (response.data.success === true && response.data.message === "PROFILE FOUND.") {
        setProfile(response.data.data);
      } else {
        console.error("Something went wrong");
      }
    });
  }, [tripId]);

  const deleteTrip = () => {
    request.put(`user/trips/${tripId}`, {
      status: "CANCELLED",
    }).then((response) => {
      if (response.data.success === true && response.data.message === "CHANGES SAVED.") {
        alert("Shipment Discarded")
        history.push(`/trips`);
      } else {
        console.error("Something went wrong");
      }
    });
  }


  if (!trip) {
    return <p>Loading</p>
  } else {
    const { cost, currency, deliveryDate, name, load } = trip;
    const { firstName, lastName, companyName, streetAddress, locale, city } = profile;
    const date = moment.utc(deliveryDate).format("D MMMM YYYY");

    return (
      <div className={style.wrapper}>
        <Text variant="xLarge">Shipment Quotation</Text>
        <div className={style.grid}>
          <div className={style.card}>
            <header className={style.header}>
              <img className={style.logo} src={logo} alt="" />
              <div className={style.invoicedetails}>
                <p className={style.title}>Invoice</p>
                <p className={style.bpn}>Thumeza Enteprises. Business Partner Number: 0200242326</p>
                <address>
                  <strong>Thumeza Enteprises Private Business Corporation</strong><br />
                  1<sup>st</sup> Floor, Netone Building, Leopold Takawira Avenue<br />
                  Bulawayo, Bulawayo +263 <br />
                  Zimbabwe
                </address>
                <p>+263778260466 <br />
                </p>
              </div>
            </header>
            <div className={style.information}>
              <div>
                <p><strong>To</strong></p>
                <p>
                  {firstName} {lastName} <br />
                  <strong>{companyName}</strong><br />
                  {streetAddress} <br />
                  {locale} {city}
                </p>
              </div>
              <div>
                <p><strong>REF:</strong> {tripId}</p>
                <p><strong>INVOICE DATE:</strong> {date}</p>
                <p><strong>PAYMENT DUE:</strong> {date}</p>
                <p><strong>AMOUNT DUE: {currency} {toCurrency(cost)}</strong></p>
              </div>
            </div>
            <ShimmeredDetailsList selectionMode={SelectionMode.none} items={[
              {
                "name": name + " " + load.tonnage,
                "quantity": 1,
                "description": load.description,
                "price": cost,
                "amount": cost
              }
            ]} enableShimmer={isLoading} columns={itemsColumns} />
            <div className={style.cost}>
              <p>Amount Due:  {currency}  {toCurrency(cost)} </p>
            </div>
            <div className={style.notes}>
              <h3>Notes</h3>
              <p>Payment Details</p>
              <p>Mobile Payment: Ecocash<br />Merchant Account: 221420<br />Account Name: THUMEZA PBC</p>
              <p>RTGS Account<br />Account Name: Thumeza PBC<br />Account Number: 5726200000639<br />Bank: Ecobank<br />Branch: Bradfield.</p>
            </div>
            <div className={style.notes}>
              <p>*Excluding insurance of losses arising to loss of market or inconsequential losses of whatsoever nature</p>
              <p>*Excluding insurance of losses due to an explosion, deliberate damage, fumigation, contamination, leakage, water gradual deterioration in quality, vaporization, mysterious disappearance, inherent vice, spontaneous, rain water, combustion, and ROD unless resulting from conveyance vehicle being involved in an accident.</p>
            </div>
            <div className={style.footnote}>
              <p>InTransit is a service offered by Thumeza Enterprises Private Business Corporation.</p>
            </div>

          </div>
          <div className={style.buttons}>
            <PrimaryButton text="Request Shipment" onClick={() => history.push(`/payment-prompt/${tripId}`)} primary /><DefaultButton text="Discard Quotation" onClick={deleteTrip} />
          </div>
        </div>
      </div>
    )
  }
}