import React, { useState } from "react"
import { AccountsHeader  } from "../../components"
import style from "./pages.module.css"
import { NavLink } from "react-router-dom"
import { PrimaryButton, IStackTokens, Stack, TextField } from "office-ui-fabric-react"
import { authRequest } from "../../helpers/request-helper"

export const ForgotPasswordPage= () => {
  const [payload, setPayload] = useState({ email: "" });

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const resetPassword = (): void => {
    authRequest.post("reset-password", payload).then((response) => {
      if (response.data.success === true && response.data.message  === "PASSWORD RESET.") {
        alert(`An email to rest the password has been sent`);
      } else {
        alert("Something went wrong");
      }
    })
  }
  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: "10px 0"
  };

  return (
    <div className={style.wrapper}>
      <AccountsHeader/>
      <div className={style.card}> 
        <h2 className={style.heading}>Enter your your details to recover your account</h2> 
        <Stack tokens={stackTokens}>
        <TextField type="email" label="Email Address" name="email" onChange={handleChange} />
        <PrimaryButton onClick={resetPassword} text="Recover My Password" />
        </Stack>
        <p className={style.instruction}>Dont have an account? <NavLink to="/signup">Sign up here</NavLink> or <NavLink to="/forgot-password">Forgot Password</NavLink></p>
      </div>
    </div>
  )
}