import React from "react";
import style from "./layout.module.css";
import { Header } from "../components/header/header";
import { Switch, Route,} from "react-router-dom";
import { AdminHomeView, AdminsView, DriversView, UsersView, PricingView, AdminTripsView, AdminVehiclesView,  DriverProfileView, ClientProfileView, AdminTripView } from "../views";
import { Navigation } from "../components";

const routes: any = [  
  { path: "/admin/", name: "Home", exact: true},
  { path: "/admin/users", name: "Clients"}, 
  { path: "/admin/drivers", name: "Drivers"}, 
  { path: "/admin/trips", name: "Trips"}, 
  { path: "/admin/vehicles", name: "Vehicles"}, 
  { path: "/admin/access", name: "Admins"},
  { path: "/admin/pricing", name: "Pricing"},
];

export const AdminLayout = () => {
  return (
    <div className={style.container}>
      <Header/>
      <Navigation routes={routes}/>
      <div className={style.wrapper}>
        <div className={style.main}>
          <main>
            <Switch>
              <Route path="/admin/users/:clientId" component={ClientProfileView}/> 
              <Route path="/admin/users" component={UsersView}/> 
              <Route path="/admin/drivers/:driverId" component={DriverProfileView}/> 
              <Route path="/admin/drivers" component={DriversView} />
              <Route path="/admin/trips/:tripId" component={AdminTripView} />
              <Route path="/admin/trips" component={AdminTripsView} />
              <Route path="/admin/access" component={AdminsView}/>
              <Route path="/admin/vehicles" component={AdminVehiclesView}/>
              <Route path="/admin/pricing" component={PricingView}/>
              <Route path="/" component={AdminHomeView}/>
            </Switch>
          </main>
        </div>
      </div>
    </div>
  )
}