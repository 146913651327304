import React, { useState, useEffect } from "react";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, ShimmeredDetailsList, SelectionMode, IColumn, Stack, Text } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { buttonStackTokens, topStackTokens, stackTokens } from "../../util/stackTokens";

export const LocationsView = () => {
  const init: any[] = [];
  const [locations, setLocations] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  const columns: IColumn[] = [{
    key: 'nameColumn',
    name: 'Location Name',
    fieldName: 'name',
    minWidth: 100,
    maxWidth: 240,
  }, {
    key: 'descriptionColumn',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 600,
  }, {
    key: 'localeColumn',
    name: 'Locale',
    fieldName: 'locale',
    minWidth: 100,
  },
  {
    key: 'cityColumn',
    name: 'City',
    fieldName: 'city',
    minWidth: 100,
  }
  ]

  useEffect(() => {
    request.get("user/locations").then((response) => {
      if (response.data.success === true && response.data.message === "USER LOCATIONS FOUND.") {
        const _locations = response.data.data.locations;
        setLocations(_locations);
        setIsLoading(false);
      } else {
        alert("Something went wrong");
      }
    });
  }, []);

  const _onItemInvoked = (item: any): void => {
    history.push(`/locations/${item.id}`);
  }

  return (
    <Stack tokens={stackTokens}>
      <Stack tokens={topStackTokens}>
        <Text variant="xLarge">Shipping Locations</Text>
        <Text variant="mediumPlus">{locations.length > 0 ? `You have ${locations.length} shipping ${locations.length === 1? 'location': 'locations'}` : "You have not added any shipping locations yet. Add a new location to get started"}</Text>
        <Stack horizontal tokens={buttonStackTokens}>
          <PrimaryButton text={`Add ${locations.length > 0 ? "Another": "New"} Location`} onClick={() => history.push("/locations/add")} />
        </Stack>
      </Stack>
      <div>
        {locations.length > 0 || isLoading ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={locations} enableShimmer={isLoading} columns={columns} onItemInvoked={_onItemInvoked} /> : <div><p>No Shipping Locations have been added yet. Add one to get started</p> </div>}
      </div>


    </Stack>
  )
}        
