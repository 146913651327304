import React, { useState, useEffect } from "react";
import { SectionHeading } from "../../components";
import { ShimmeredDetailsList, SelectionMode, IColumn, Text } from "office-ui-fabric-react";
import style from "./users.module.css";
import { request } from "../../helpers/request-helper";
import { useHistory } from "react-router-dom";

export const UsersView = () => {
    const init: any[] = [];
    const [users, setUsers] = useState(init);
    const [isLoading, setIsLoading] = useState(true);
    let history = useHistory();
    
    const columns: IColumn[] = [{
      key: 'nameColumn',
      name: 'User Name',
      fieldName: 'name',
      minWidth: 100,
    }, {
      key: 'emailColumn',
      name: 'Email',
      fieldName: 'email',
      minWidth: 200,
    }, {
      key: 'statusColumn',
      name: 'Status',
      fieldName: 'status',
      minWidth: 200,
    },
    {
      key: 'typeColumn',
      name: 'User Type',
      fieldName: 'type',
      minWidth:100
    }, 
    ]


    useEffect(() => {
        request.get("/admin/users").then((response) => {
            if (response.data.success === true && response.data.message === "ALL USERS FOUND.") {
                setUsers(response.data.data.users.filter((user: any) => {return user.type === "client"}));
                setIsLoading(false)
            } else {
                console.error("Something went wrong");
            }
        });
    }, []);

    const _onItemInvoked = (item: any): void => {
      localStorage.setItem("profile", JSON.stringify(item));
      history.push(`users/${item.id}`)
    }

  return (
    <div>
      <div>
        <SectionHeading title="Clients" />
        <Text variant="small" block>UNVERIFIED: Client registered but has not verified their email address. INACTIVE: Client account was deactivated by an admin. VERIFIED: Client verified their email address and has access. (This setting may be changed from each profile)</Text>
      </div>
      <div className={style.cards}>
      {users ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={users} enableShimmer={isLoading} columns={columns} onItemInvoked={_onItemInvoked} /> : null}
      </div>
    </div>
  )
}