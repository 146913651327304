import React, { useEffect, useState } from "react";
import { request } from "../../helpers/request-helper";
import { ShimmeredDetailsList, SelectionMode, IColumn, PrimaryButton, Dialog, DialogType, TextField, DialogFooter, DefaultButton, Dropdown, Stack, Text } from "office-ui-fabric-react";
import { stackTokens, topStackTokens, buttonStackTokens } from "../../util/stackTokens";

export const VehiclesView = () => {
  const initial: any[] = []
  const [vehicles, setVehicles] = useState(initial);
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleTypes, setVehicleTypes] = useState(initial);
  const [vehicleType, setVehicleType] = useState();
  const [make, setMake] = useState();
  const [model, setModel] = useState();
  const [color, setColor] = useState();
  const [year, setYear] = useState();
  const [numberPlate, setNumberPlate] = useState();
  const [transportersLicense, setTransportersLicense] = useState();

  const [isDialogClosed, setIsDialogClosed] = useState(true);

  const vehicleColumns: IColumn[] = [{
    key: 'make',
    name: 'Vehicle Make',
    fieldName: 'make',
    minWidth: 50,
    maxWidth: 100,
  }, {
    key: 'model',
    name: 'Vehicle Model',
    fieldName: 'model',
    minWidth: 50,
    maxWidth: 100,
  }, {
    key: 'numberPlate',
    name: 'Number Plate',
    fieldName: 'numberPlate',
    minWidth: 50,
    maxWidth: 100,
  }, {
    key: 'transportersLicence',
    name: 'Transporter License',
    fieldName: 'transportersLicense',
    minWidth: 150,
    maxWidth: 300
  }, {
    key: 'color',
    name: 'Vehicle Color',
    fieldName: 'color',
    minWidth: 60,
    maxWidth: 200,
  }];

  useEffect(() => {
    request.get("driver/vehicles").then((response) => {
      if (response.data.success === true && response.data.message === "DRIVER VEHICLES FOUND.") {
        setVehicles(response.data.data.vehicles);
        setIsLoading(false);
      } else {
        console.error("Something went wrong");
      }
    });
    request.get("public/vehicles/types").then((response) => {
      if (response.data.success === true && response.data.message === "VEHICLE TYPES FOUND.") {
        const typeOptions = response.data.data.vehicleTypes.map((type: any) => ({ key: type.id, text: type.name }));
        setVehicleTypes(typeOptions);
      } else {
        //
      }
    });
  }, []);

  const addVehicle = () => {
    request.post("driver/vehicles", {
      make: make,
      vehicleTypeId: vehicleType,
      color: color,
      model: model,
      year: year,
      numberPlate: numberPlate,
      transportersLicense: transportersLicense
    }).then((response) => {
      if (response.data.success === true && response.data.message === "VEHICLE CREATED.") {
        setVehicles([response.data.data, ...vehicles]);
      } else {
        console.error("Something went wrong");
      }
    });
    toggleDialogClosed();
  }

  const toggleDialogClosed = () => {
    setIsDialogClosed(!isDialogClosed);
  }


  return (
    <Stack tokens={stackTokens}>
      <Stack tokens={topStackTokens}>
        <Text variant="xLarge">Vehicles</Text>
        <Text variant="mediumPlus">{vehicles.length > 0 ? `You have ${vehicles.length} vehicles in your fleet.` : "You have not added any vehicles yet. Add a vehicle to start accepting trips"}</Text>
        <Stack horizontal tokens={buttonStackTokens}>
          <PrimaryButton text={vehicles.length > 0 ? 'Add another Vehicle' : 'Add a Vehicle'} onClick={toggleDialogClosed} />
        </Stack>
      </Stack>
      <div>
        {vehicles.length > 0 || isLoading ?
          vehicles ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={vehicles} enableShimmer={isLoading} columns={vehicleColumns} /> : null
          : null
        }
      </div>

      <Dialog hidden={isDialogClosed} onDismiss={toggleDialogClosed} dialogContentProps={{ type: DialogType.normal, title: 'Add a new Vehicle' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <TextField label="Vehicle Make" type="email" onChange={(e, v) => { setMake(v) }} />
        <TextField label="License Plate Number" onChange={(e, v) => { setNumberPlate(v) }} />
        <TextField label="Model" onChange={(e, v) => { setModel(v) }} />
        <Dropdown placeholder="Select a Vehicle Class" label="Vehicle Class" options={vehicleTypes} onChange={(e, i) => setVehicleType(i?.key)} />
        <TextField label="Color" onChange={(e, v) => { setColor(v) }} />
        <TextField label="Year" onChange={(e, v) => { setYear(v) }} />
        <TextField label="Transporter's License Number" onChange={(e, v) => { setTransportersLicense(v) }} />


        <DialogFooter>
          <PrimaryButton onClick={addVehicle} text="Add Vehicle" />
          <DefaultButton onClick={toggleDialogClosed} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </Stack>
  )
}
