import React, { useEffect, useState } from "react";
import { request } from "../../helpers/request-helper";
import { useHistory } from "react-router-dom";
import * as moment from 'moment';
import { SelectionMode, ShimmeredDetailsList, IColumn } from "office-ui-fabric-react";
import { toCurrency } from "../../helpers/currency";
import { SectionHeading } from "../../components";



export const AdminTripsView = () => {
  let history = useHistory();

  const tripColumns: IColumn[] = [{
    key: 'id',
    name: 'Trip ID',
    fieldName: 'id',
    minWidth: 50,
    maxWidth:100,
  },{
    key: 'deliveryDate',
    name: 'Shipping Date',
    fieldName: 'deliveryDate',
    minWidth: 50,
    maxWidth:100,
    onRender: (item: any)=> {
      const date = moment.utc(item.deliveryDate).format("D MMMM YYYY");
      return date;
    }
  },
  {
    key: 'nameColumn',
    name: 'Delivery Name',    
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 60,
  }, {
    key: 'costColumn',
    name: 'Cost',
    fieldName: 'cost',
    minWidth: 60,
    onRender: (item: any)=> {
      return toCurrency(item.cost);
    }
  }];

  const init: any[] = [];
  const [trips, setTrips] = useState(init);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    request.post("admin/trips/search").then((response) => {
      if (response.data.success === true && response.data.message === "TRIPS FOUND.") {
        setTrips(response.data.data.trips);   
        setIsLoading(false);
      } else {
        console.error("Something went wrong");
      }
    });

 
  }, []);

  const _onItemInvoked = (item: any): void => {
    history.push(`/admin/trips/${item.id}`);
  }



  return (
    <div>
      <div>
        <SectionHeading title="Trips" />
      </div>
      <div>
        {(trips.length > 0 || isLoading) ?
          <ShimmeredDetailsList selectionMode={SelectionMode.none} items={trips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onItemInvoked} />
          :
        <p>No trips have been added yet</p> 
        }
      </div>
    </div>
  )
}