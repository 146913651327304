import React from "react";
import style from "./navigation.module.css";
import { NavLink } from "react-router-dom";

interface INavigationProps {
  routes: any[]
}
export const Navigation = ( props: INavigationProps) => {
  const {routes} = props;

  return (
    <nav className={style.wrapper}>
      {routes.map((route: any) => (
        <NavLink key={route.path} className={style.link} activeClassName={style.active} to={route.path} exact={route.exact}>{route.name}</NavLink>      
      ))}
    </nav>
  )
}