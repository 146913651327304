import React, { useState, useEffect, useRef } from "react";
import style from "./estimate.module.css"
import { request } from "../../helpers/request-helper";
import { useHistory, NavLink } from "react-router-dom";
import { PrimaryButton, TextField, Dropdown, IStackTokens, Stack, Separator, ActionButton } from "office-ui-fabric-react";

declare global {
  interface Window {
    cloudinary: any;
  }
};

export const EstimateView = () => {
  let init: any[] = [];
  const [locations, setLocations] = useState(init);
  const [vehicleTypes, setVehicleTypes] = useState(init);
  const [categories, setCategories] = useState(init);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [vehicleType, setVehicleType] = useState();
  const [loadCategory, setLoadCategory] = useState();
  const [tonnage, setTonnage] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [date, setDate] = useState();
  const [pickupPoint, setPickUpPoint] = useState();
  const [dropoffPoint, setDropoffPoint] = useState();
  const [contactPersonFirstName, setContactPersonFirstName] = useState();
  const [contactPersonLastName, setContactPersonLastName] = useState();
  const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState();
  const [contactPersonJobTitle, setContactPersonJobTitle] = useState();

  const widgetRef = useRef();
  const history = useHistory();

    const containerStackTokens: IStackTokens = { childrenGap: 20 };


    const handleImageUpload = (url: string) => {
        console.log(url);
        setImageUrl(url);
    }

  useEffect(() => {

    const widget: any = window.cloudinary.createUploadWidget({
      cloudName: "projects-cloud",
      uploadPreset: "g9p0bzg5",
    },
      (error: any, result: any) => {
        if (result.info.url !== undefined) {
            handleImageUpload(result.info.url);
            debugger;
        }
    });

    widgetRef.current = widget;

    request.get("user/locations").then((response) => {
      if (response.data.success === true && response.data.message === "USER LOCATIONS FOUND.") {
        const locationOptions = response.data.data.locations.map((location: any) => ({ key: location.id, text: location.name }));
        setLocations(locationOptions);
      } else {
        //
      }
    }).catch(error => {
        console.error(error);
        alert("Something went wrong. Please check your internet connection");
    }); 

    request.get("public/vehicles/types").then((response) => {
      if (response.data.success === true && response.data.message === "VEHICLE TYPES FOUND.") {
        const typeOptions = response.data.data.vehicleTypes.map((type: any) => ({ key: type.id, text: type.name }));
        setVehicleTypes(typeOptions);
      } else {
        //
      }
    }).catch(error => {
        console.error(error);
        alert("Something went wrong. Please check your internet connection");
    });

    request.get("public/load-categories").then((response) => {
      if (response.data.success === true && response.data.message === "LOAD CATEGORIES FOUND.") {
        const categoryOptions = response.data.data.loadCategories.map((category: any) => ({ key: category.id, text: category.name }));
        setCategories(categoryOptions);
      } else {
        //
      }
    }).catch(error => {
        console.error(error);
        alert("Something went wrong. Please check your internet connection");
    });;
    
  }, []);

  const showWidget = () => {
    if (widgetRef.current) {
      // @ts-ignore: Object is possibly 'null'.
      widgetRef.current.open();
    }
    }

  const getEstimate = () => {
    if (tonnage < 0) {
      alert("Tonnage cannot be negative")
    } else {
      request.post("user/trips",
        {
          "name": name,
          "image": imageUrl,
          "description": description,
          "vehicleTypeId": vehicleType,
          "loadCategoryId": loadCategory,
          "dimensions": "0",
          "deliveryDate": date,
          "tonnage": tonnage,
          "pickupLocationId": pickupPoint,
          "dropoffLocationId": dropoffPoint,
          "returnTrip": false,
          "contactPerson": {
            "firstName": contactPersonFirstName,
            "lastName": contactPersonLastName,
            "phoneNumber": contactPersonPhoneNumber,
            "jobTitle": contactPersonJobTitle,
          }
        }).then((response) => {
          if (response.data.success === true && response.data.message === "TRIP CREATED.") {
            alert("An quotation has been created");
            const id = response.data.data.id;
            history.push(`/confirm-trip/${id}`)
          } else if (response.data.success === false && response.data.message === "INVALID TOKEN.") {
            alert("You cannot do this")
          } else if (response.data.success === false && response.data.message === "MISSING OR INVALID PARAMETERS.") {
            const numberOfMissingParams = response.data.data.missing.length;
            if (numberOfMissingParams > 3) {
              alert("Multiple details required to calculate your quotation are missing. Please fill them in");
            } else {
              alert("Not all details have been added. Please review your details");
            }

          }
        }).catch(error => {
            console.error(error)
            alert("Something went wrong.");
        });;
    }
    }

    const refreshLocations = () => {
        request.get("user/locations").then((response) => {
            if (response.data.success === true && response.data.message === "USER LOCATIONS FOUND.") {
                const locationOptions = response.data.data.locations.map((location: any) => ({ key: location.id, text: location.name }));
                setLocations(locationOptions);
                alert("Locations refreshed.");
            } else {
                //
            }
        }).catch(error => {
            console.error(error);
            alert("Something went wrong. Please check your internet connection");
        });
    }


  return (
    <div className={style.wrapper}>
      <div className={style.grid}>
        <Stack tokens={containerStackTokens}>
          <div>
            <h3 className={style.title}>Shipment Quotation</h3>
            <p className={style.description}>Enter the details of your shipment</p>
          </div>
          <TextField label="Name of Goods" onChange={(e, v) => setName(v)} />
          <TextField label="Load Description" multiline autoAdjustHeight onChange={(e, v) => setDescription(v)} />
          <div style={{ marginTop: 40 }}>
            <div className={style.upload}>
              <PrimaryButton style={{ margin: "auto" }} text={imageUrl.length > 0 ? "Change Photo" : "Upload Photo"} onClick={showWidget} />
            </div>
            <div className={style.group}>
              <TextField type="date" label="Shipment Date" onChange={(e, v) => setDate(v)} />
              <TextField label="Weight in Tonnes" type="number" min={0} onChange={(e, v) => setTonnage(v)} />
            </div>
            <div className={style.group}>
              <Dropdown placeholder="Select a Vehicle Class" label="Vehicle Class" options={vehicleTypes} onChange={(e, i) => setVehicleType(i?.key)} />
              <Dropdown placeholder="Select a Load Category" label="Load Category" options={categories} onChange={(e, i) => setLoadCategory(i?.key)} />
            </div>
            <div className={style.group}>
              <Dropdown placeholder="Pick a Source" label="Pickup Point" options={locations} onChange={(e, i) => setPickUpPoint(i?.key)} />
              <Dropdown placeholder="Pick a Destination" label="Dropoff Point" options={locations} onChange={(e, i) => setDropoffPoint(i?.key)} />
            </div>
                      <Stack reversed horizontal verticalAlign="center" tokens={{ childrenGap: 5, padding: 10, }}>
                          <ActionButton iconProps={{ iconName: 'Refresh' }} allowDisabledFocus onClick={refreshLocations}>Refresh Locations</ActionButton>
                          <NavLink to="locations/add" target="_blank" style={{ textDecoration: "none" }}>Add a location</NavLink>
                              
                          </Stack>
            <div style={{ marginTop: 40, marginBottom: 30 }}>
              <Separator>Contact Person</Separator>
              <div className={style.group}>
                <TextField label="Contact Person First Name" type="text" onChange={(e, v) => setContactPersonFirstName(v)} />
                <TextField label="Contact Person Last Name" type="text" onChange={(e, v) => setContactPersonLastName(v)} />
                <TextField label="Contact Person Job Title" type="text" onChange={(e, v) => setContactPersonJobTitle(v)} />
                <TextField label="Contact Person Phone Number" type="tel" onChange={(e, v) => setContactPersonPhoneNumber(v)} />
              </div> 
            </div>
            <div style={{ marginTop: 20 }}>
              <PrimaryButton text="Calculate Quotation" onClick={getEstimate} />
            </div>
          </div>
        </Stack>
      </div>
    </div>
  )
}