import React, { useState } from "react"
import { AccountsHeader } from "../../components"
import style from "./pages.module.css"
import { NavLink } from "react-router-dom"
import { PrimaryButton, TextField, Stack, IStackTokens } from "office-ui-fabric-react"
import { authRequest } from "../../helpers/request-helper"

export const ResendVerification = () => {
  const [payload, setPayload] = useState({ email: "" });

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const resendVerificationEmail = (): void => {
    authRequest.post("resend-email-verification", payload).then((response) => {
      if (response.data.success === true) {
        alert(`A verification email has been sent to ${payload.email}`);
      } else {
        alert("Something went wrong");
      }
    })
  }
  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: "10px 0"
  };
  return (
    <div className={style.wrapper}>
      <AccountsHeader />
      <div className={style.card}>
        <h2 className={style.heading}>Enter your your email address to resend a verification email</h2>
        <Stack tokens={stackTokens}>
          <TextField type="email" label="Email Address" name="email" onChange={handleChange} />
          <PrimaryButton text="Resend Verification" onClick={resendVerificationEmail} />
        </Stack>
        <p className={style.instruction}>Dont have an account? <NavLink to="/signup">Sign up here</NavLink> or <NavLink to="/forgot-password">Forgot Password</NavLink></p>
      </div>
    </div>
  )
}