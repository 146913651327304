import React, { useState, useEffect } from "react";
import style from "./pricing.module.css"
import { SectionHeading } from "../../components";
import { request } from "../../helpers/request-helper";
import { PrimaryButton, DefaultButton, IColumn, ShimmeredDetailsList, SelectionMode, Dialog, DialogType, DialogFooter, TextField, Stack, IStackTokens } from "office-ui-fabric-react";


export const PricingView = () => {
  const init: any[] = [];

  const [vehicleTypes, setVehicleTypes] = useState(init);
  const [categories, setCategories] = useState(init);
  const [vehicleType, setVehicleType] = useState();
  const [description, setDescription] = useState();
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogSubtext, setDialogueSubtext] = useState();
  const [newCostCoefficient, setNewCostCoeffiecient] = useState();
  const [newLoadCategory, setNewLoadCategory] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hideCategoryDialog, setHideCategoryDialog] = useState(true);
  const [hideTypeDialog, setHideTypeDialog] = useState(true);
  const [dialogClosed, setDialogClosed] = useState(true);
  const [dialogContext, setDialogContext] = useState("");
  const [dialogAction, setDialogAction] = useState();
  const [foo, setFoo] = useState();
  const [rate, setRate] = useState();



  const vehicleTypeColumns: IColumn[] = [{
    key: 'nameColumn',
    name: 'Vehicle Type',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'descriptionColumn',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
  }, {
    key: 'coefficientColumn',
    name: 'Coefficient',
    fieldName: 'costCoefficient',
    minWidth: 100,
  }]

  const categoryColumns: IColumn[] = [{
    key: 'nameColumn',
    name: 'Category Name',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'coefficientColumn',
    name: 'Coefficient',
    fieldName: 'costCoefficient',
    minWidth: 100,
  }];

  useEffect(() => {
    request.get("public/load-categories").then((response) => {
      if (response.data.success === true && response.data.message === "LOAD CATEGORIES FOUND.") {
        setCategories(response.data.data.loadCategories);
      } else {
        //
      }
    });
    request.get("public/vehicles/types").then((response) => {
      if (response.data.success === true && response.data.message === "VEHICLE TYPES FOUND.") {
        setVehicleTypes(response.data.data.vehicleTypes);
      } else {
        //
      }
    });

    setIsLoading(false);

  }, []);

  const addLoadCategory = () => {
    request.post("admin/load-categories", {
      name: newLoadCategory,
      costCoefficient: newCostCoefficient,
    }).then((response) => {
      if (response.data.success === true && response.data.message === "LOAD CATEGORY CREATED.") {
        setCategories([...categories, response.data.data]);
      } else {
        //
      }
    });
    toggleCategoryDialog();
  }

  const addVehicleType = () => {
    request.post("admin/vehicles/types", {
      name: vehicleType,
      costCoefficient: newCostCoefficient,
      description: description,
    }).then((response) => {
      if (response.data.success === true && response.data.message === "VEHICLE TYPE CREATED.") {
        setVehicleTypes([...vehicleTypes, response.data.data]);
      } else {
        //
      }
    });

    toggleTypeDialog();
  }

  const deleteLoadCategory = () => {
    alert("Load Category has been deletd");
  }

  const deleteVehicleType = () => {
    request.delete(`admin/vehicles/types/${foo.id}`).then((response) => {
      if (response.data.success === true) {
        setVehicleTypes(vehicleTypes.filter((type) => {
          return type.id !== foo.id;
        }));
        alert("Vehicle Category has been deleted")
      }
    })

    toggleDialogClosed();
  }


  const toggleCategoryDialog = () => {
    setHideCategoryDialog(!hideCategoryDialog);
  }

  const toggleDialogClosed = () => {
    setDialogClosed(!dialogClosed);
  }
  const toggleTypeDialog = () => {
    setHideTypeDialog(!hideTypeDialog);
  }

  const _onLoadCategoryInvoked = (item: any): void => {
    setFoo(item.id);
    setDialogTitle(`${item.name} - ${item.costCoefficient}`);
    setDialogueSubtext(null);
    setDialogContext("Load Category");
    setDialogAction(() => deleteLoadCategory);
    setDialogClosed(false);

  }

  const _onVehicleTypeInvoked = (item: any): void => {
    setFoo(item);
    console.log(foo);
    setDialogTitle(`${item.name} - ${item.costCoefficient}`);
    setDialogueSubtext(item.description);
    setDialogContext("Vehicle Class");
    setDialogAction(() => deleteVehicleType);
    setDialogClosed(false);
  }

  const stackTokens: IStackTokens = { childrenGap: 10 };
  return (
    <div className={style.wrapper}>
      <div className="m-15">
        <SectionHeading style={{ textAlign: "center" }} title="Pricing" />
        <div>
          <div className={style.card}>
            <p className={style.title}>Load Categories</p>
            {categories ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={categories} enableShimmer={isLoading} columns={categoryColumns} onItemInvoked={_onLoadCategoryInvoked} /> : null}
            <div className={style.controls}>
              <PrimaryButton text="Add Load Category" onClick={toggleCategoryDialog} />
            </div>
          </div>
          <div className={style.card}>
            <p className={style.title}>Vehicle Classes</p>
            {vehicleTypes ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={vehicleTypes} enableShimmer={isLoading} columns={vehicleTypeColumns} onItemInvoked={_onVehicleTypeInvoked} /> : null}
            <div className={style.controls}>
              <PrimaryButton text="Add Vehicle Class" onClick={toggleTypeDialog} />
            </div>
          </div>
        </div>
      </div>
      <Dialog hidden={hideCategoryDialog} onDismiss={toggleCategoryDialog} dialogContentProps={{ type: DialogType.normal, title: 'Add a new Category', subText: 'Add a new load category' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <TextField label="Category Name" value={newLoadCategory} onChange={(e, v) => { setNewLoadCategory(v) }} />
        <TextField label="Cost Coefficient" type="number" value={newCostCoefficient} onChange={(e, v) => { setNewCostCoeffiecient(v) }} />
        <DialogFooter>
          <PrimaryButton onClick={addLoadCategory} text="Add Load Category" />
          <DefaultButton onClick={toggleCategoryDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog hidden={hideTypeDialog} onDismiss={toggleTypeDialog} dialogContentProps={{ type: DialogType.normal, title: 'Add a new Vehicle Type', subText: 'Add a New Vehicle Type' }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        <TextField label="Vehicle Type Name" value={vehicleType} onChange={(e, v) => { setVehicleType(v) }} />
        <TextField label="Description" value={description} onChange={(e, v) => { setDescription(v) }} />
        <TextField label="Cost Coefficient" type="number" value={newCostCoefficient} onChange={(e, v) => { setNewCostCoeffiecient(v) }} />
        <DialogFooter>
          <PrimaryButton onClick={addVehicleType} text="Add Vehicle Class" />
          <DefaultButton onClick={toggleTypeDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog hidden={dialogClosed} onDismiss={toggleDialogClosed} dialogContentProps={{ type: DialogType.normal, title: dialogContext, subText: dialogTitle }} modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } }, }}>
        {dialogSubtext ? <p>{dialogSubtext}</p> : null}
        <DialogFooter>
          <DefaultButton onClick={toggleDialogClosed} text="Dismiss" />
        </DialogFooter>
      </Dialog>
    </div>
  )
}