import React from "react";
import style from "./location-item.module.css";

export const LocationItem = (props: any) => {
  const { locale, name, streetAddress, city } = props;
  return (
    <div className={style.wrapper}>
      <p className={style.name}>{name}</p>
      <p className={style.address}>{streetAddress}, {locale}, {city}</p>
    </div>
  )
}