import React from "react";
import style from "./pages.module.css";
import { NavLink } from "react-router-dom";
import truck from "./truck.svg";
import business from "./business.svg";
import { AccountsHeader } from "../../components";

export const SignupPage = () => {

  return (
    <div className={style.wrapper}>
      <AccountsHeader/>
      <div className={style.grid}>
        <NavLink className={style.card} to="/driver-signup">
          <img className={style.icon} src={truck} alt=""/>
          <p className={style.option}>Signup as a Driver</p>
        </NavLink>
        <NavLink className={style.card} to="/user-signup"> 
          <img className={style.icon} src={business} alt=""/>
          <p className={style.option}>Signup as a Client</p>
        </NavLink>
      </div>
    </div>
  )
}