import React, { useState } from "react";
import style from "./location-picker.module.css";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";


const MapComponent = withScriptjs(withGoogleMap((props: any) => {
  const {setLatitude, setLongitude} = props;
  const [lat, setLat] = useState(-20.13527);
  const [lng, setLng] = useState(28.55708);

  const onMarkerDrop = (e: any) => {
    let newLat = e.latLng.lat(), newLng = e.latLng.lng;
    setLat(newLat);
    setLng(newLng);
    setLatitude(newLat);
    setLongitude(newLng);
  }

  return (
    <GoogleMap defaultZoom={14} defaultCenter={{ lat: lat? lat : -20.13527, lng: lng? lng: 28.55708 }} options={{ disableDefaultUI: true }}>
      <Marker draggable={true} position={{ lat: lat? lat : -20.13527, lng: lng? lng: 28.55708 }} onDragEnd={onMarkerDrop} />
    </GoogleMap>)
}));


interface ILocationInputProps {
  setLongitude: any;
  setLatitude:any;
}

export const LocationInput = ( props: ILocationInputProps) => {
  const {setLatitude, setLongitude} = props;
  return (
    <div className={style.wrapper}>
      <MapComponent 
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB1L4yicVAZkUhC2cA-lBQfp3nZ47_RDtA&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )
}
