import React, { useState, useEffect } from "react"
import { SectionHeading } from "../../components";
import { request } from "../../helpers/request-helper";
import { useHistory } from "react-router-dom";
import * as moment from 'moment';
import { IColumn, ShimmeredDetailsList, SelectionMode, Text } from "office-ui-fabric-react";
import { toCurrency } from "../../helpers/currency";
import { LoginContext } from "../../App";
import { IToken } from "../../interfaces/Token";

export const AdminHomeView = () => {

  const init: any[] = [];
  const [trips, setTrips] = useState(init);
  const [drivers, setDrivers] = useState(init);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();


  const tripColumns: IColumn[] = [{
    key: 'id',
    name: 'Trip ID',
    fieldName: 'id',
    minWidth: 50,
    maxWidth: 100,
  }, {
    key: 'deliveryDate',
    name: 'Shipping Date',
    fieldName: 'deliveryDate',
    minWidth: 50,
    maxWidth: 100,
    onRender: (item: any) => {
      const date = moment.utc(item.deliveryDate).format("D MMMM YYYY");
      return date;
    }
  }, {
    key: 'nameColumn',
    name: 'Delivery Name',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 60,
  }, {
    key: 'costColumn',
    name: 'Cost',
    fieldName: 'cost',
    minWidth: 60,
    onRender: (item: any) => {
      return toCurrency(item.cost);
    }
  }];

  const driverColumns: IColumn[] = [{
    key: 'nameColumn',
    name: 'User Name',
    fieldName: 'name',
    minWidth: 100,
  }, {
    key: 'emailColumn',
    name: 'Email',
    fieldName: 'email',
    minWidth: 200,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 200,
  },
  {
    key: 'typeColumn',
    name: 'User Type',
    fieldName: 'type',
    minWidth: 100
  },
  ];

  useEffect(() => {
    request.post("admin/trips/search").then((response) => {
      if (response.data.success === true && response.data.message === "TRIPS FOUND.") {
        setTrips(response.data.data.trips);
      } else {
        console.error("Something went wrong");
      }
    });

    request.get("/admin/users").then((response) => {
      if (response.data.success === true && response.data.message === "ALL USERS FOUND.") {
        setDrivers(response.data.data.users.filter((user: any) => { return user.type === "driver" && user.status === "INACTIVE"}));
        setIsLoading(false)
      } else {
        console.error("Something went wrong");
      }
    });

    setIsLoading(false);
  }, []);

  const _onTripInvoked = (item: any): void => {
    history.push(`/admin/trips/${item.id}`)
  }

  const _onDriverInvoked = (item: any): void => {
    history.push(`/admin/drivers/${item.id}`)
  }
  
  const pendingTrips = trips.filter((trip: any) => {
    return trip.status === "PENDING";
  });

  return (
    <LoginContext.Consumer>
    {([token, setToken]) => {
      const _token = token as IToken;
      return (
    <div>
      <Text variant="xxLarge">Good Day {_token.user.name}</Text>
      <section> 
        <SectionHeading title="Pending Trips (unaccepted)" />
        {pendingTrips.length > 0 ?
          <div>
           
            {pendingTrips ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={pendingTrips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onTripInvoked} /> : null}
          </div>
          : <Text variant="medium" block>There currently are not any pending trips that are yet to be accepted by a driver</Text>
        }

      </section>
      <section>  
        <SectionHeading title="Drivers Pending Review" />
        {drivers.length > 0 ?
          <div>
          
            {drivers ? <ShimmeredDetailsList selectionMode={SelectionMode.none} items={drivers} enableShimmer={isLoading} columns={driverColumns} onItemInvoked={_onDriverInvoked} /> : null}
          </div>
          : <Text variant="medium" block>There currently are not any drivers who are pending review and verfification</Text>
        }
      </section>
    </div>
  )}}
  </LoginContext.Consumer>)
  ;
}
