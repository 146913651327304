import React, { useState } from "react"
import { AccountsHeader  } from "../../components"
import style from "./pages.module.css"
import { useParams } from "react-router-dom"
import { PrimaryButton, TextField, IStackTokens, Stack } from "office-ui-fabric-react"
import {  authRequest } from "../../helpers/request-helper"

export const NewPasswordPage= () => {
  let { code } = useParams();
  const [payload, setPayload] = useState({ code: code,  password:""});

  const handleChange = (e: any) => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const setNewPassword = () => {
    authRequest.post("change-password", payload).then((response) => {
      if (response.data.success === true) {
        alert("Password changed successfully");
      } else {
        console.error("Something went wrong");
      }
    });
  }
  
  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: "10px 0"
  };

  return (
    <div className={style.wrapper}>
      <AccountsHeader/>
      <div className={style.card}> 
        <h2 className={style.heading}>Enter your new passoword</h2> 
        <Stack tokens={stackTokens}>
        <TextField label="New Password" name="password" type="passoword" placeholder="Enter your new Password" onChange={handleChange} />
        <div style={{marginTop: 20}}>
         <PrimaryButton text="Recover My Password" onClick={setNewPassword} /> 
        </div>
        </Stack>
      </div>
    </div>
  )
}