import React from 'react';
import ReactDOM from 'react-dom';
import { Fabric, loadTheme, initializeIcons } from 'office-ui-fabric-react';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

loadTheme({
  palette: {
    themePrimary: '#f7941d',
    themeLighterAlt: '#fdf6f4',
    themeLighter: '#f5dcd3',
    themeLight: '#edbfb0',
    themeTertiary: '#da866a',
    themeSecondary: '#c95731',
    themeDarkAlt: '#ae3e18',
    themeDark: '#933415',
    themeDarker: '#6d270f',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }
});

initializeIcons();

ReactDOM.render(<Fabric><App /></Fabric>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
