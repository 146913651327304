import React from "react";
import style from "./status-badge.module.css";

export enum Status {
  Listed = "Listed",
  Accepted = "Accepted",
  Dispatched = "Dispatched",
  Delayed = "Delayed",
  Intransit = "Intransit", 
  Deliverd = "Delivered", 
}

interface IStatusBadgeProps {
  status: Status | string;
}
export const StatusBadge = (props:  IStatusBadgeProps) => {
  const {status} = props;

  return (
    <div className={style.wrapper}>  
      {status}
    </div>
  )
}