import React from "react";
import { SubNavigation } from "../sub-navigation/sub-navigation";

export const AccountsHeader = () => {
  return (
    <div>

    <SubNavigation/>
    </div>

  )
}