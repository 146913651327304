import React, { useEffect, useState } from "react";
import { request } from "../../helpers/request-helper";
import { IColumn, ShimmeredDetailsList, SelectionMode, Text, Stack } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { SectionHeading } from "../../components";
import { stackTokens, topStackTokens } from "../../util/stackTokens";

export const DriverTripsView = () => {
  const init: any[] = [];
  const [trips, setTrips] = useState(init);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();


  const tripColumns: IColumn[] = [{
    key: 'id',
    name: 'Trip ID',
    fieldName: 'id',
    minWidth: 50,
    maxWidth: 100,
  }, {
    key: 'deliveryDate',
    name: 'Shipping Date',
    fieldName: 'deliveryDate',
    minWidth: 50,
    maxWidth: 100,
    onRender: (item: any) => {
      const date = moment.utc(item.deliveryDate).format("D MMMM YYYY");
      return date;
    }
  },
  {
    key: 'nameColumn',
    name: 'Delivery Name',
    fieldName: 'name',
    minWidth: 80,
  }, {
    key: 'statusColumn',
    name: 'Status',
    fieldName: 'status',
    minWidth: 60,
  }, {
    key: 'costColumn',
    name: 'Cost',
    fieldName: 'cost',
    minWidth: 60,
  }];

  useEffect(() => {
    request.get("/driver/trips").then((response) => {
      if (response.data.success === true && response.data.message === "TRIPS FOUND.") {
        setTrips(response.data.data.trips);

      } else {
        console.error("Something went wrong");
      }
      setIsLoading(false);
    });
  }, []);

  const _onItemInvoked = (item: any): void => {
    history.push(`trips/${item.id}`)
  }


  return (
    <Stack tokens={stackTokens}>
      <Stack tokens={topStackTokens}>
        <SectionHeading title="Trips" />
        <Text variant="mediumPlus">Your ongoing shipments and shipment history</Text>
      </Stack>
      <div>
        {trips.length > 0 || isLoading ?
          <ShimmeredDetailsList selectionMode={SelectionMode.none} items={trips} enableShimmer={isLoading} columns={tripColumns} onItemInvoked={_onItemInvoked} /> : null
        }
      </div>
    </Stack>
  )
}